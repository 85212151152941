// @flow

import type {
  EntityRelationship,
  TargetEntities,
  TargetEntity,
  EntityMappingsByEntityType,
} from 'src/types/target-entity-types';
import type {
  AudienceEntity,
  AudienceEntityData,
} from 'src/types/multientity-audience';
import type {
  AtsEntity,
  EntityMappingsByName,
  EntityType,
  EntityMappingsBySenseName,
  EntitySenseName,
} from 'src/types/ats-entities';
import type {EntitySummary} from 'src/types/entity-summaries';

import invariant from 'invariant';
import startCase from 'lodash/startCase';
import zip from 'lodash/zip';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';

import snakeCase from 'lodash/snakeCase';


const entityMap: {[string]: EntityType, ...} = {
  bh_submittal: 'bh_job_submission',
};

// the keys here are typically the 'sense_name' for some attribute
// --marcos
export const entityColorMapping = {
  internal_employee: {
    name: 'purple',
    text: 'colorPurpleShadow',
    background: 'colorPurpleTint',
  },
  ab_web_applicant: {
    name: 'purple',
    text: 'colorPurpleShadow',
    background: 'colorPurpleTint',
  },
  ab_pipeline: {
    name: 'purple',
    text: 'colorPurpleShadow',
    background: 'colorPurpleTint',
  },
  candidate_pipeline: {
    name: 'purple',
    text: 'colorPurpleShadow',
    background: 'colorPurpleTint',
  },
  candidate: {
    name: 'turquoise',
    text: 'colorTurquoise',
    background: 'colorTurquoiseTint',
  },
  task: {
    name: 'turquoise',
    text: 'colorTurquoise',
    background: 'colorTurquoiseTint',
  },
  job: {
    name: 'deepPurple',
    text: 'colorDeepPurpleShadow',
    background: 'colorDeepPurpleTint',
  },
  placement: {
    name: 'orange',
    text: 'colorOrangeShadow',
    background: 'colorOrangeTint',
    border: 'colorOrangeHighlight',
  },
  appointment: {
    name: 'brown',
    text: 'colorBrownShadow',
    background: 'colorBrownTint',
  },
  client_company_employee: {
    name: 'darkGreen',
    text: 'colorDarkGreenShadow',
    background: 'colorDarkGreenTint',
  },
  client_company: {
    name: 'blueGray',
    text: 'colorBlueGray',
    background: 'colorBlueGrayTint',
  },

  bh_job_submission: {
    name: 'red',
    text: 'colorRedShadow',
    background: 'colorRedTint',
  },
  bh_candidate_education: {
    name: 'yellow',
    text: 'colorYellowShadow',
    background: 'colorYellowTint',
  },
  candidate_qualification: {
    name: 'yellow',
    text: 'colorYellowShadow',
    background: 'colorYellowTint',
  },
  bh_candidate_reference: {
    name: 'deepPurple',
    text: 'colorDeepPurpleShadow',
    background: 'colorDeepPurpleTint',
  },
  bh_lead: {
    name: 'darkGreen',
    text: 'colorDarkGreenShadow',
    background: 'colorDarkGreenTint',
  },
  er_seed: {
    name: 'darkGreen',
    text: 'colorDarkGreenShadow',
    background: 'colorDarkGreenTint',
  },
  er_candidate_application: {
    name: 'deepPurple',
    text: 'colorDeepPurpleShadow',
    background: 'colorDeepPurpleTint',
  },
  er_match: {
    name: 'orange',
    text: 'colorOrangeShadow',
    background: 'colorOrangeTint',
  },
  jd_billing_record: {
    name: 'red',
    text: 'colorRedShadow',
    background: 'colorRedTint',
  },
  billing_record: {
    name: 'red',
    text: 'colorRedShadow',
    background: 'colorRedTint',
  },
  jd_activity: {
    name: 'orange',
    text: 'colorOrangeShadow',
    background: 'colorOrangeTint',
  },
  jd_salary_record: {
    name: 'brown',
    text: 'colorBrownShadow',
    background: 'colorBrownTint',
  },
  ae_task: {
    name: 'purple',
    text: 'colorPurpleShadow',
    background: 'colorPurpleTint',
  },

  messaging_contact: {
    name: 'turquoise',
    text: 'colorTurquoise',
    background: 'colorTurquoiseTint',
  },
  messaging_agent: {
    name: 'red',
    text: 'colorRedShadow',
    background: 'colorRedTint',
  },
  job_match_variable: {
    name: 'purple',
    text: 'colorPurpleShadow',
    background: 'colorPurpleTint',
  },
};

// TODO (kyle): this is from the new designs but not sure we need it
const entityPrimaryColorMapping = {
  candidate: 'green',
  client_company_employee: 'orange',
  client_company: 'blue',
  job: 'red',
  bh_job_submission: 'red',
  placement: 'lightBlue',

  messaging_contact: 'lightBlue',
  messaging_agent: 'red',
};

// For backward compatibility only. No need to add more than Bullhorn and PCR,
// because those are the ATSes that have been supported since before the schema
// change.
export const backwardCompatibilityEntityColorMapping = {
  bh_corporate_user: entityColorMapping.internal_employee,
  bh_candidate: entityColorMapping.candidate,
  bh_job_order: entityColorMapping.job,
  bh_job_submission: entityColorMapping.bh_job_submission,
  bh_placement: entityColorMapping.placement,
  bh_appointment: entityColorMapping.appointment,
  bh_client_contact: entityColorMapping.client_company_employee,
  bh_client_corporation: entityColorMapping.client_company,

  pcr_position: entityColorMapping.job,
  pcr_user: entityColorMapping.internal_employee,
  pcr_candidate: entityColorMapping.candidate,
  pcr_contact: entityColorMapping.client_company_employee,
  pcr_interview: entityColorMapping.appointment,
  pcr_company: entityColorMapping.client_company,
};

/**
 * Workflow audience table columns by type (sense_name)
 * sense_name can be mapped from atsEntities.entities in the store
 */
export const entityAudienceFields: {
  [EntitySenseName]: string[],
} = {
  candidate: ['fullName', 'primaryEmail', 'primaryPhone'],
  client_company_employee: [
    'fullName',
    'primaryEmail',
    'primaryPhone',
    'companyName',
  ],
  internal_employee: ['fullName', 'primaryEmail', 'primaryPhone'],
  placement: ['candidateName', 'companyName', 'startDate', 'endDate'],
  job_submission: ['companyName', 'submissionDate', 'candidateName'],
  task: ['taskName', 'taskStatus', 'internalEmployeeName'],
  bh_job_submission: ['companyName', 'submissionDate', 'candidateName'],
  appointment: ['candidateName', 'companyName', 'interviewDate'],
  bh_candidate_education: [
    'candidateName',
    'certificationName',
    'expirationDate',
  ],
  candidate_qualification: [
    'candidateName',
    'certificationName',
    'expirationDate',
  ],
  bh_candidate_reference: [
    'externalSourceId',
    'fullName',
    'primaryEmail',
    'primaryPhone',
  ],
  bh_lead: [
    'externalSourceId',
    'fullName',
    'primaryEmail',
    'primaryPhone',
    'companyName',
  ],
  candidate_reference: [
    'externalSourceId',
    'fullName',
    'primaryEmail',
    'primaryPhone',
  ],
  lead: [
    'externalSourceId',
    'fullName',
    'primaryEmail',
    'primaryPhone',
    'companyName',
  ],
  bh_certification: ['candidateName', 'certificationName', 'expirationDate'],
  certification: ['candidateName', 'certificationName', 'expirationDate'],
  bh_placement_certification: [
    'candidateName',
    'certificationName',
    'expirationDate',
  ],
  placement_certification: [
    'candidateName',
    'certificationName',
    'expirationDate',
  ],
  bh_opportunity: ['status', 'title'],
  opportunity: ['status', 'title'],
  ae_certification: ['candidateName', 'name', 'date_expiration'],
  ae_submission: ['companyName', 'date_application', 'candidateName'],
  job: ['externalSourceId', 'companyName', 'status', 'title'],
  jd_activity: ['atsId', 'candidateName', 'companyName'],
  jd_billing_record: ['atsId', 'candidateName', 'companyName'],
  billing_record: ['atsId', 'candidateName', 'companyName'],
  er_match: ['candidateName', 'companyName', 'startDate', 'endDate'],
  jd_salary_record: [],
  er_seed: ['fullName', 'primaryEmail', 'primaryPhone'],
  er_candidate_application: ['candidateName', 'status', 'createdOn'],
  es_applicant: ['candidateName'],
  applicant: ['candidateName'],
  es_workflow: [
    'candidateName',
    'onboardingRequest',
    'orderedDate',
    'expirationDate',
  ],
  workflow: [
    'candidateName',
    'onboardingRequest',
    'orderedDate',
    'expirationDate',
  ],
  tr_submission: ['candidateName'],
  ab_web_applicant: ['candidateName', 'companyName', 'applyDate'],
  ab_pipeline: [
    'candidateName',
    'companyName',
    'pipelinedDate',
    'declinedDate',
    'nominatedDate',
  ],
  candidate_pipeline: [
    'candidateName',
    'companyName',
    'pipelinedDate',
    'declinedDate',
    'nominatedDate',
  ],
  ro_submission: ['candidateName', 'companyName'],
  ro_opportunity_discussed: ['candidateName'],
  ro_application: ['candidateName'],
  sfdc_submission: ['candidateName', 'companyName'],
  sfdc_opportunity: ['status', 'title'],
};

export const entityRecordsFields: Map<string, string[]> = new Map(
  Object.entries(entityAudienceFields).map(([name, fields]) => [
    name,
    // $FlowFixMe this is for sure an array
    fields.map(snakeCase).filter((field) => field !== 'candidate_name'),
  ]),
);

export const entityRecordFieldNames: Map<string, string> = new Map<
  string,
  string,
>(
  // $FlowIssue
  Object.entries({
    external_source_id: 'ID',
    company_name: 'Company',
    start_date: 'Start Date',
    end_date: 'End Date',
    submission_date: 'Date',
    interview_date: 'Date',
    expiration_date: 'Date',
    created_on: 'Created On',
    status: 'Status',
    title: 'Title',
    ats_id: 'ATS ID',
    entity_type: 'Entity Type',
    apply_date: 'Apply Date',
    certification_name: 'Certification Name',
  }),
);

export const getJourneyDescription = (
  name: EntityType,
  sense_name: EntitySenseName,
  isDbCleanup?: boolean,
  labelI18nInstance?: mixed,
): string => {
  invariant(
    name,
    'Cannot access journey description because entity type is unavailable',
  );

  const defaultDescriptions = isDbCleanup
    ? bulkWritebackDefaultDescriptions
    : getJourneyDefaultDescriptions(labelI18nInstance);
  const atsSpecificDescriptions = isDbCleanup
    ? bulkWritebackAtsSpecificDescription
    : journeyAtsSpecificDescription;

  // TODO (kyle): Reflect is not correctly typed.
  const description = Reflect.has(atsSpecificDescriptions, name)
    ? Reflect.get(atsSpecificDescriptions, name)
    : Reflect.get(defaultDescriptions, sense_name);

  return description;
};
//using Refect.has here to avoid flow erroring out on journeyAtsSpecificDescription not having an override string for every entity type

const getJourneyDefaultDescriptions = (labelI18nInstance) => {
  const placementLabel = labelI18nInstance
    ? // $FlowFixMe[incompatible-use]
      labelI18nInstance.t('sense_placement', 'placement')
    : 'placement';
  /**
   * - Description of sample use case of the journey
   * - Only valid event entities are included.
   */
  const journeyDefaultDescriptions = {
    candidate:
      'Keep new and database candidates, and their internal owners, engaged',
    appointment:
      'Keep people in the loop about a candidate\'s interview process',
    placement:
      'Reach out to people during a candidate\'s ' +
      placementLabel.toLowerCase() +
      ', run a re-deployment campaign, and contact active candidates',
    internal_employee: 'Any internal communications for your company',
    client_company_employee:
      'Send an update to your client contact when their status changes',
    job: 'Send an update internally if a job has been posted for a certain length of time but not filled',
    task: 'Notify people of a task\'s status',
    bh_candidate_education:
      'Send an update when a candidate\'s education is nearing expiration',
    candidate_qualification:
      'Send an update when a candidate\'s education is nearing expiration',
    bh_candidate_reference: 'Send a message to a candidate\'s references',
    candidate_reference: 'Send a message to a candidate\'s references',
    bh_lead: 'Reach out to leads and/or their owner',
    lead: 'Reach out to leads and/or their owner',
    job_submission: 'Notify people of a candidate\'s submission status',
    bh_job_submission: 'Notify people of a candidate\'s submission status',
    bh_certification:
      'Keep candidates up to date on the status of their certifications',
    certification:
      'Keep candidates up to date on the status of their certifications',
    bh_placement_certification:
      'Remind candidates of expiring certifications associated with the placement record',
    placement_certification:
      'Remind candidates of expiring certifications associated with the placement record',
    bh_opportunity:
      'Represents a possible Opportunity which can be converted to a JobOrder',
    opportunity:
      'Represents a possible Opportunity which can be converted to a JobOrder',
    sfdc_opportunity: 'Represents a possible Opportunity',
    er_match:
      'Set up a journey based around a candidate\'s job submission, interview, or placement.',
    er_seed:
      'Reach out to new prospects that might become candidates and/or their owner',
    er_candidate_application:
      'Keep candidates up to date on the status of their application',
    jd_activity:
      'Set up a journey based around a candidate\'s job submission, interview, or placement.',
    jd_billing_record:
      'Send a journey based around a billing record for an activity',
    billing_record:
      'Send a journey based around a billing record for an activity',
    tr_submission: 'Notify people of a candidate\'s submission status',
    tr_reference: 'Send a message to a candidate\'s references',
    ro_submission: 'Notify people of a candidate\'s submission status',
    ro_opportunity_discussed:
      'Notify people of status of the opportunities discussed with a candidate',
    ro_application: 'Notify people of a candidate\'s application status',
    ae_certification: 'Notify people of a candidate\'s certification',
    ae_submission: 'Notify people of a candidate\'s submission',
    es_applicant: 'Set up a journey based around Applicant\'s status in Able',
    applicant: 'Set up a journey based around Applicant\'s status in Able',
    es_workflow: 'Set up a journey based around Onboarding requests in Able',
    workflow: 'Set up a journey based around Onboarding requests in Able',
    ab_web_applicant:
      'Pre-screen new web applicants and send application confirmation messages to candidates',
    sfdc_submission: 'Notify people of a candidate\'s submission status',
    sfdc_reference: 'Send a message to a candidate\'s references',
    sk_offer_letter_approver:
      'Send a message to the approvers of an offer letter',
    sk_hiring_team_member:
      'Send a message to the hiring team members of an offer',
    sk_interview_schedule: 'Send communication regarding an upcoming interview',
    sk_application: 'Send communication to the candidates of a job application',
    sk_interviewer:
      'Send communication to interviewer about any upcoming interview',
  };
  return journeyDefaultDescriptions;
};

const journeyAtsSpecificDescription = {
  tp_applicant:
    'Send out updates to all of your candidates at one time to keep them engaged',
  tp_placement:
    'Reach out to active applicants to check-in on how their placement is going or provide updates for applicants prior to their start date',
  ab_pipeline:
    'Keep candidates warm and informed as they make their way through the pipelined talent stages on a job.',
  candidate_pipeline:
    'Keep candidates warm and informed as they make their way through the pipelined talent stages on a job.',
};

const bulkWritebackAtsSpecificDescription = {
  //add when there are overwrite descriptions
};

export const bulkWritebackDefaultDescriptions = {
  candidate:
    'Update your candidate\'s owners in bulk if a recruiter has recently left the company.',
  appointment: 'Update the location or times for a group of interviews.',
  placement:
    'Update your candidate\'s dateavailable fields to be a day after their most recent placement\'s end date.',
  internal_employee: '',
  client_company_employee:
    'Update the owner\'s for your company\'s client contacts.',
  job: 'Change the statuses for existing job orders.',
  bh_candidate_education: '',
  candidate_qualification: '',
  bh_candidate_reference: 'Clean-up reference information in bulk.',
  // https://sensehq.atlassian.net/browse/ENGAGE-7715
  job_submission: 'Add comments to a set of job submissions.',
  bh_job_submission: 'Add comments to a set of job submissions.',
  bh_certification: '',
  bh_lead: 'Update the statuses for a group of leads based on activity',
  er_match: '',
  jd_activity: '',
  jd_billing_record: '',
  billing_record: '',
  certification: 'Update your candidate\'s certification details in bulk',
  lead: 'Update the statuses for a group of leads based on activity',
};

/**
 * - display alternatives for non person entity used in typeahead and simulate event
 * - uses sense name for mapping
 * - created for Send Test Email/SMS modal
 * - adding nulls for other entity types to satisfy flow check
 * - TODO: maybe change this variable name so that it describes what this constant is better
 */
export const nonPersonEntityDisplay: {
  [EntitySenseName]: ?{
    typeAheadPlaceholder: string,
    typeAheadPrimaryLabel: string,
    typeAheadSecondaryLabel: string,
    relatedEntityOptionLabel: string,
  },
} = {
  job: {
    typeAheadPlaceholder: 'Google',
    typeAheadPrimaryLabel: 'name', // in the case of job order, this is the name of the company
    typeAheadSecondaryLabel: 'externalSourceId',
    relatedEntityOptionLabel: 'title',
  },
  placement: null,
  placement_certification: null,
  billing_record: null,
  opportunity: null,
  candidate_stage: null,
  candidate_pipeline: null,
  internal_employee: null,
  client_company_employee: null,
  candidate: null,
  appointment: null,
  task: null,
  bh_job_submission: null,
  bh_candidate_reference: null,
  bh_candidate_education: null,
  candidate_qualification: null,
  bh_certification: {
    typeAheadPlaceholder: 'Certification',
    typeAheadPrimaryLabel: 'candidateName',
    typeAheadSecondaryLabel: 'externalSourceId',
    relatedEntityOptionLabel: 'certificationName',
  },
  //CE-2808 just copied from bh_certification
  certification: {
    typeAheadPlaceholder: 'Certification',
    typeAheadPrimaryLabel: 'candidateName',
    typeAheadSecondaryLabel: 'externalSourceId',
    relatedEntityOptionLabel: 'certificationName',
  },
  ae_certification: {
    typeAheadPlaceholder: 'Certification',
    typeAheadPrimaryLabel: 'candidateName',
    typeAheadSecondaryLabel: 'externalSourceId',
    relatedEntityOptionLabel: 'certificationName',
  },
  ae_task: null,
  bh_placement_certification: null,
  bh_lead: null,
  bh_opportunity: null,
  jd_salary_record: null,
  jd_billing_record: null,
  jd_activity: null,
  er_match: null,
  tr_submission: null,
  ro_submission: null,
  ro_opportunity_discussed: null,
  ro_application: null,
  es_applicant: null,
  applicant: null,
  es_workflow: null,
  workflow: null,
  er_candidate_application: null,
  ab_web_applicant: null,
  sfdc_submission: null,
  sfdc_opportunity: null,
  ab_pipeline: null,
};

const searchableEntityBySenseName: {[string]: string, ...} = {
  candidate: 'candidate',
  client_company_employee: 'client_company_employee',
  internal_employee: 'internal_employee',
  bh_lead: 'bh_lead',
  bh_candidate_reference: 'candidate',
  bh_placement_certification: 'candidate',
  placement_certification: 'candidate',
  job: 'client_company',
  placement: 'candidate',
  sense_candidate_match: 'candidate',
  appointment: 'candidate',
  task: 'internal_employee',
  bh_job_submission: 'candidate',
  bh_candidate_education: 'candidate',
  candidate_qualification: 'candidate',
  bh_opportunity: 'internal_employee',
  opportunity: 'internal_employee',
  bh_certification: 'candidate',
  er_match: 'candidate',
  jd_activity: 'candidate',
  jd_billing_record: 'candidate',
  billing_record: 'candidate',
  // TODO (kyle): should this be `'candidate'`?
  jd_salary_record: 'jd_candidate',
  tr_submission: 'candidate',
  ro_submission: 'candidate',
  ro_opportunity_discussed: 'candidate',
  ro_application: 'candidate',
  candidate_opportunity: 'candidate',
  candidate_application: 'candidate',
  er_candidate_application: 'candidate',
  ab_web_applicant: 'candidate',
  ab_pipeline: 'candidate',
  candidate_pipeline: 'candidate',
  job_submission: 'candidate',
  es_applicant: 'candidate',
  applicant: 'candidate',
  es_workflow: 'candidate',
  workflow: 'candidate',
  sfdc_submission: 'candidate',
  sfdc_opportunity: 'internal_employee',
  ae_task: 'internal_employee',
};

export const getSearchableEntity = (
  atsEntityMappings: AtsEntity[],
  entityType: AtsEntity,
): ?AtsEntity => {
  const searchablePersonEntity =
    searchableEntityBySenseName[entityType.sense_name];

  return searchablePersonEntity
    ? atsEntityMappings.find(
        (entity) => entity.sense_name === searchablePersonEntity,
      )
    : entityType;
};

export const getEntityFieldName = (entity: EntityType): EntityType => {
  const mappedName = entityMap[entity];
  return mappedName ? mappedName : entity;
};

export const prettyBullhornLabel = (value: string): string =>
  startCase(value.replace('bh_', ''));

export const getMEFullName = (personEntity: AudienceEntity): string =>
  getMEFullNameFromData(personEntity.data);

export const getMEFullNameFromData = (data: AudienceEntityData): string => {
  if (data.name) {
    // for ats contacts received via chrome extension sync button
    return data.name;
  } else if (data['full name']) {
    return `${data['full name']}`;
  } else if (data.fullname) {
    // PCR until summary api code is out
    return data.fullname;
  } else if (data.full_name) {
    return String(data.full_name);
  } else if (data.contactname) {
    // PCR until summary api code is out
    return String(data.contactname);
  } else {
    const firstName =
      data.firstname ||
      (data['first name'] ? `${data['first name']}` : null) ||
      '';
    const lastName =
      data.lastname ||
      (data['last name'] ? `${data['last name']}` : null) ||
      '';
    const space = firstName && lastName ? ' ' : '';
    return `${firstName}${space}${lastName}`;
  }
};

export function getPhoneNumberFromEntityData(
  entityData: AudienceEntityData,
): string | void {
  const exactAttributes = ['phone number', 'phoneNumber'];

  for (const attribute of exactAttributes) {
    const value = entityData[attribute];
    // $FlowFixMe[sketchy-number-and]
    if (value && typeof value === 'string') {
      return value;
    }
  }

  const fuzzyAttributes = ['phone', 'mobile', 'cell'];

  for (const term of fuzzyAttributes) {
    for (const attributeName in entityData) {
      if (attributeName.toLowerCase().includes(term)) {
        const value = entityData[attributeName];
        if (typeof value === 'string') {
          return value;
        }
      }
    }
  }
}

/**
 * Entity types that have tabs on the candidate page
 * can be used to link to a specific tab on the candidate page
 *
 * Note (angelina): This is a temporary constant that will removed
 * once the backend can return whether an entity has a tab
 * on the candidate page
 */
export const candidatePageTabs: Set<string> = new Set([
  'appointment',
  'placement',
  'er_match',
  'bh_job_submission',
  'jd_activity',
  'jd_billing_record',
  'jd_salary_record',
]);

/**
 * Helper that generates a pathname to be used to navigate to
 * associated person pages based on entity type
 *
 * @return a pathname to be used with the react-router <Link /> component
 * example usage:
 * <Link to={{pathname}}>link text</Link>
 */
export const getEntityPageLink = ({
  entityMeta,
  entityData,
}: {
  entityMeta: AtsEntity,
  entityData: EntitySummary,
}): string => {
  const hasCandidatePageTab = candidatePageTabs.has(entityMeta.sense_name);

  if (entityMeta.is_person) {
    invariant(
      entityMeta.url,
      'Person entity must have a `url` to generate a link.',
    );
    return `/people/${entityMeta.url}/${entityData.id}`;
  } else if (entityMeta.is_internal) {
    invariant(
      entityData.internalEmployeeId,
      'Internal Employee must have an `internalEmployeeId` to generate a link.',
    );
    return `/people/internals/${entityData.internalEmployeeId}/profile`;
  } else {
    invariant(
      typeof entityData.candidateId === 'string',
      'Related entity must have a `candidateId` to generate a link.',
    );
    if (hasCandidatePageTab && entityMeta.url) {
      return `/people/candidates/${entityData.candidateId}/records`;
    } else {
      return `/people/candidates/${entityData.candidateId}`;
    }
  }
};

export const getPossibleEventRecipients = ({
  atsEntityMappings,
  relatedEntities,
  entityType,
}: {
  atsEntityMappings: EntityMappingsBySenseName,
  relatedEntities: any[],
  entityType: EntityType,
}): string[] => {
  const entityMappingsByName = keyBy(atsEntityMappings, 'name');
  const personEntity = entityMappingsByName[entityType]?.is_person
    ? [entityMappingsByName[entityType]?.display_name]
    : [];

  return (
    relatedEntities
      .filter((entity) => entityMappingsByName[entity.entity_type]?.is_person)
      .map((entity) => (entity.name ? entity.name.replace('pcr_', '') : ''))
      //NOTE (angelina): escaping pcr_ is a temporary hack until display_name is added for related entities
      .concat(personEntity)
  );
};

//NOTE:(diwakersurya) this won't work in case targetLabel
//contains /
export function getAttributeRelationshipPath(
  targetLabel: string,
  baseEntityType: EntityType,
  entityMap: EntityMappingsByName,
): string[] {
  const path = targetLabel.split('/').reverse();
  const displayEntityType = entityMap[baseEntityType]?.display_name;

  if (displayEntityType) {
    path.push(displayEntityType);
  }

  return path;
}

// TODO(marcos): use these prefxes when rendering the list
export function generateAttributeGrammarTokens(
  inputTokens: string[],
): string[] {
  if (inputTokens.length > 3 && inputTokens.length % 2 === 0) {
    const prefixes = ['the', 'for the', 'of the', 'for the', 'of the'];
    return flatten(zip(prefixes.slice(0, inputTokens.length), inputTokens));
  } else {
    const prefixes = ['the', 'of the', 'for the', 'of the', 'for the'];
    return flatten(zip(prefixes.slice(0, inputTokens.length), inputTokens));
  }
}

export const nonPersonStandardEntityDisplayMapping: Map<
  string,
  ?{
    typeAheadPlaceholder: string,
    typeAheadPrimaryLabel: string,
    typeAheadSecondaryLabel: string,
    relatedEntityOptionLabel: string,
  },
> = new Map([
  [
    'job',
    {
      typeAheadPlaceholder: 'Google',
      typeAheadPrimaryLabel: 'title', // in the case of job order, this is the name of the company
      typeAheadSecondaryLabel: 'external_source_id',
      relatedEntityOptionLabel: 'title',
    },
  ],
  ['placement', null],
  ['placement_certification', null],
  ['billing_record', null],
  ['opportunity', null],
  ['candidate_stage', null],
  ['candidate_pipeline', null],
  ['internal_employee', null],
  ['client_company_employee', null],
  ['candidate', null],
  ['appointment', null],
  ['task', null],
  ['job_submission', null],
  ['candidate_reference', null],
  ['candidate_qualification', null],
  [
    'certification',
    {
      typeAheadPlaceholder: 'Certification',
      typeAheadPrimaryLabel: 'candidateName',
      typeAheadSecondaryLabel: 'external_source_id',
      relatedEntityOptionLabel: 'certificationName',
    },
  ],
  ['lead', null],
  ['billing_record', null],
  ['workflow', null],
  ['applicant', null],
  ['candidate_pipeline', null],
]);

export const topLevelStandardSearchableEntities = [
  'candidate',
  'job',
  'client_company',
];
//Candidate, job, client_company,

export const getOrderedRelatedPersonEntities = (
  relatedEntities: Array<EntityRelationship>,
  entityMappings: EntityMappingsByEntityType,
): TargetEntities => {
  const result = [];
  relatedEntities.forEach((entity) => {
    const currentRelatedEntity = entityMappings[entity.entityType];
    if (currentRelatedEntity.person) {
      result.push(currentRelatedEntity);
    }
  });
  //Todo(Vish): Order result based on some logic given by backend.
  return result;
};
export const getSearchableEntityFromStandardEntities = (
  entity: ?TargetEntity,
  entityMappings: EntityMappingsByEntityType,
): ?TargetEntity => {
  if (
    topLevelStandardSearchableEntities.includes(entity?.standard_entity_type) ||
    entity?.person
  ) {
    return entity;
  }
  const relatedPersonEntities = getOrderedRelatedPersonEntities(
    entity?.related_entities ?? [],
    entityMappings,
  );
  if (relatedPersonEntities.length > 0) {
    return relatedPersonEntities[0];
  }
  return null;
};
