// @flow

// $FlowFixMe[untyped-type-import]
import type {Workflow, Event} from 'src/api-parsers';
import type {RouteLocation, Params} from 'src/types/router';

import type {AtsEntity} from 'src/types/ats-entities';
import type {AllowedTearsheetTypes} from 'src/types/ats-settings';

import * as React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Link} from 'src/rerouter';
import {useHistory} from 'src/rerouter/hooks.js';
import {partsMatch} from 'src/rerouter/utils';
import {EDIT_PERMISSIONS} from 'src/action-creators/roles';
import useDisplayConfig, {useJ2Enabled} from 'src/hooks/useDisplayConfig';
import {useApi} from 'src/hooks/useApi';
import {classify, titleCase} from 'src/utils';
import {getSelectedKey} from 'src/utils/app-shell.js';
import {
  useReleaseFlag,
  useAnalyticsV2,
  useServiceAccounts,
  useTRMEnabled,
  useSchedulerHolidyManagement,
  useLiveAgentTransfer,
  useSchedulerGenesis,
  useAppShellSettingsEnabled,
} from 'src/hooks/product-flags';
import {hasPrivilege} from 'src/utils/accounts';
import useAgencyConfig, {
  useChatbotEnabled,
  useSchedulerEnabled,
  useJobMatchEnabledForAnyProduct,
  useSenseEvents,
} from 'src/hooks/useAgencyConfig';
import usePrivilege from 'src/hooks/usePrivilege';
import useReferrals from 'src/hooks/useReferrals';
import useI18n from 'src/hooks/useI18n';

import {getAllowedTearsheetTypes} from 'src/action-creators/ats-settings';
import {toggleSideNav} from 'src/action-creators/navigation';
import {
  selectDNSV2Enabled,
  selectAgencyConfigFlag,
  selectAgencyConfig,
  selectEnableChatbot,
} from 'src/selectors/agency';
import {canUseChatPreRelease} from 'src/selectors/chat';
import {getAtsEntityMappings} from 'src/selectors/ats-entities';

import {SideMenuLink} from '@spaced-out/ui-design-system/lib/components/SideMenuLink';

import {SideMenuGroup} from 'src/components/app-shell/side-menu-group.jsx';

import {SideNavHeader} from 'src/components/app-shell/side-nav-header.jsx';
import {AccountBar} from 'src/components/app-shell/account-bar.jsx';

import Pusher from 'src/components/navigation/pusher/pusher.jsx';
import {
  AnalyticsWorkflowList,
  DefaultWorkflowList,
} from 'src/components/navigation/workflow-detail.jsx';
import ScrollView from 'src/components/lib/scroll-view.jsx';
import Loading from 'src/components/lib/loading/loading.jsx';
import TruncatedText from 'src/components/lib/truncated-text';
import TopNavLink from 'src/components/navigation/top-nav-link.jsx';
import MessagesNavLink from 'src/components/messaging/messages-nav-link.jsx';
import ZendeskTrigger from 'src/components/lib/zendesk-trigger';
import {isInternalUser} from 'src/utils/check-internal-user';
import {
  ANALYTICS_API_V3,
  checkJdGeneratorEnabled,
  checkAnalyticsEmbeddedEnabled,
  checkAnalyticsNewLandingPageEnabled,
} from 'src/utils/analytics-api-migration';

import ArrowLeft from 'src/images/arrow-left.svg';
import AnalyticsEngagementCohortsDetail from 'src/components/navigation/analytics-engagement-cohorts-detail.jsx';

import MetricsIcon from 'src/images/nav/metrics-icon.svg?noAttrs';
import ResponsesIcon from 'src/images/nav/responses-icon.svg';
import FlagIcon from 'src/images/flag-icon.svg?noAttrs';
import AtsFieldsIcon from 'src/images/ats-settings.svg?noAttrs';
import EngagementIcon from 'src/images/nav/engagement-icon.svg?noAttrs';
import ClicksIcon from 'src/images/nav/clicks-icon.svg?noAttrs';
import NpsIcon from 'src/images/nav/nps.svg?noAttrs';
import PerformanceIcon from 'src/images/nav/performance-icon.svg?noAttrs';
import JobMatchesIcon from 'src/images/nav/job_matches.svg?noAttrs';
import ChatbotAnalyticsIcon from 'src/images/nav/chatbot-analytics-icon.svg?noAttrs';
import ChatbotTranscriptsIcon from 'src/images/nav/chatbot-transcripts-icon.svg?noAttrs';
import MessagesAnalyticsIcon from 'src/images/nav/messages-analytics-icon.svg?noAttrs';

import TemplateIcon from 'src/images/template-icon.svg?noAttrs';
import SmartphoneIcon from 'src/images/icons/smartphone-icon.svg?noAttrs';
import GlobalVariableIcon from 'src/images/global-variable-icon.svg?noAttrs';
import PhoneIcon from 'src/images/phone-icon.svg?noAttrs';
import EmailIcon from 'src/images/email-icon.svg?noAttrs';
import ReportsIcon from 'src/images/icons/reports-icon.svg?noAttrs';
import ReferralGlyphIcon from 'src/images/icons/referral-glyph-icon.svg?noAttrs';
import SubscriptionIcon from 'src/images/icons/setting-subscription.svg?noAttrs';
import DeliveryHistoryIcon from 'src/images/icons/delivery-history-icon.svg?noAttrs';

import ChatbotIcon from 'src/images/nav/chatbot.svg';
import DashboardIcon from 'src/images/icons/dashboard-nav-icon.svg';
import JourneysIcon from 'src/images/icons/journeys-nav-icon.svg';
import CleanupIcon from 'src/images/icons/cleanup-nav-icon.svg';
import AnalyticsIcon from 'src/images/icons/analytics-nav-icon.svg';
import AuditIcon from 'src/images/icons/audit-nav-icon.svg';
import ScheduleIcon from 'src/images/icons/schedule-nav-icon.svg';
import PeopleIcon from 'src/images/icons/people-nav-icon.svg';
import JobsIcon from 'src/images/icons/jobs-nav-icon.svg';
import TrmIcon from 'src/images/icons/trm-nav-icon.svg';
import MessagesIcon from 'src/images/icons/messages-nav-icon.svg';
import ContactsIcon from 'src/images/icons/contacts-nav-icon.svg';
import SettingsIcon from 'src/images/icons/settings-nav-icon.svg';
import BrandingIcon from 'src/images/icons/setting-branding.svg?noAttrs';
import AtsMappingIcon from 'src/images/icons/settings-ats-mapping-icon.svg?noAttrs';
import UsersIcon from 'src/images/icons/settings-users-icon.svg';
import SettingsNumberIcon from 'src/images/icons/settings-number-icon.svg';
import SettingsRolesIcon from 'src/images/icons/settings-roles-icon.svg';
import SettingsGroupsIcon from 'src/images/icons/settings-groups-icon.svg';
import ReferralIcon from 'src/images/icons/referral-icon.svg';
import AnalyticsReferralIcon from 'src/images/icons/referral-analytics-icon.svg';
import SchedulerIcon from 'src/images/icons/calendar-grid.svg';
import NavReferralIcon from 'src/images/icons/referral-nav-icon.svg';
import BlankCalendarIcon from 'src/images/icons/calendar-blank.svg?noAttrs';
import MeetingsAnalyticsIcon from 'src/images/icons/meetings-product.svg';
import CalendarMenuIcon from 'src/images/icons/calendar-menu.svg?noAttrs';
import CalendarStarIcon from 'src/images/icons/calendar-star.svg?noAttrs';
import CalendarAddIcon from 'src/images/icons/calendar-add.svg?noAttrs';
import ContentSubscriptionIcon from 'src/images/icons/content-subscription-icon.svg?noAttrs';
import ClientContactIcon from 'src/images/client-contact.svg?noAttrs';
import {Icon} from '@spaced-out/ui-design-system/lib/components/Icon';
import TadaIcon from 'src/images/icons/tada.svg?noAttrs';
import ProgramsSettingsIcon from 'src/images/programs-side-bar-icon.svg?noAttrs';
import CommunicationsIcon from 'src/images/communications-nav-icon.svg';
import EventsIcon from 'src/images/icons/events-nav-icon.svg';
import JdIcon from 'src/images/icons/jd-nav-icon.svg';

import PagesIcon from 'src/images/icons/pages-nav-icon.svg';
import AnalyticsEmbedIcon from 'src/images/nav/analytics-beta-icon.svg';

import css from './app-shell-side-nav.css';


type AppShellSideNavProps = {
  location: RouteLocation,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflows: {[id: string]: Workflow},
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow?: Workflow,
  // $FlowFixMe[value-as-type] [v1.32.0]
  events: ?(Event[]),
  curClient?: string,
  hidden: boolean,
  canViewAnalytics: boolean,
  canViewAudienceMembers: boolean,
  canViewWorkflows: boolean,
  canEditWorkflows: boolean,
  canEditSettings: boolean,
  canViewChat: boolean,
  canViewChatorEditChatbot: boolean,
  analyticsPerformancePageEnabled: boolean,
  analyticsCohortsEnabled: boolean,
  schedulingAllowed: boolean,
  schedulerEnabled: boolean,
  params: Params,
  canViewReferrals: boolean,
  expanded: boolean,
  setExpand: (boolean) => void,
  handleSenseUniversityOnClick: () => void,
};

export const AppShellSideNav = (props: AppShellSideNavProps): React.Node => {
  const {
    workflows,
    events,
    location,
    hidden,
    canViewAnalytics,
    canViewAudienceMembers,
    canViewWorkflows,
    canEditWorkflows,
    canEditSettings,
    canViewChat,
    canViewChatorEditChatbot,
    analyticsPerformancePageEnabled,
    analyticsCohortsEnabled,
    schedulingAllowed,
    schedulerEnabled,
    params,
    canViewReferrals,
    expanded,
    setExpand,
    handleSenseUniversityOnClick,
  } = props;
  const dispatch = useDispatch();
  const {pathname} = location;
  const atsBulkWritebackEnabled = useSelector((state) =>
    selectAgencyConfigFlag(state, 'atsBulkWritebackEnabled'),
  );
  const [showWorkflows, setShowWorkflows] = React.useState(false);
  const trmEnabled = useTRMEnabled();
  const appShellSettingsEnabled = useAppShellSettingsEnabled();

  React.useEffect(() => {
    setShowWorkflows(false);
  }, [pathname]);

  const canNavigateToHomepage = useReleaseFlag('journeysHomepage');
  const genesisEnabled = useSchedulerGenesis();
  const handleWorkflowClick = React.useCallback(
    (event: SyntheticEvent<>) => {
      if (canNavigateToHomepage) {
        return;
      }
      event.preventDefault();
      setShowWorkflows(!showWorkflows);
    },
    [showWorkflows],
  );

  const handleAnalyticsClick = React.useCallback(
    (event: SyntheticEvent<>) => {
      const analyticsMatches = pathname.match(/^\/analytics$/);
      if (analyticsMatches) {
        event.preventDefault();
        setShowWorkflows(false);
      }
    },
    [pathname],
  );

  // TODO (kyle): figure out if all this matching is necessary
  let currentWorkflowId;

  const workflowMatches = pathname.match(/^\/journey\/(\d+)/);
  if (workflowMatches) {
    currentWorkflowId = workflowMatches && workflowMatches[1];
  }

  const analyticsMatches = pathname.match(/^\/analytics/);

  const analyticsWorkflowMatches = pathname.match(
    /^\/analytics\/journey\/(\d+)/,
  );
  if (analyticsWorkflowMatches) {
    currentWorkflowId = analyticsWorkflowMatches[1];
  }

  const hideSupportButton = ['survey', 'messages', 'contacts'].some((path) =>
    pathname.startsWith(`/${path}`),
  );

  const details = [];

  const customerBusinessType =
    useSelector(selectAgencyConfig).customerBusinessType;
  const isCorporate = customerBusinessType === 'corporate';

  if (canViewWorkflows && showWorkflows) {
    details.push(
      <DefaultWorkflowList
        key="workflows"
        workflows={workflows}
        canEdit={canEditWorkflows}
      />,
    );
  } else if (
    canViewAnalytics &&
    ((analyticsMatches && !checkAnalyticsNewLandingPageEnabled()) ||
      pathname === '/analytics' ||
      pathname.includes('/analytics/journey'))
  ) {
    details.push(
      // NOTE (kyle): to keep this component usefully pure, we only pass the pathname when it possibly matches one of its links
      <AnalyticsDetail
        key="analytics"
        workflows={workflows}
        location={location}
        analyticsPerformancePageEnabled={
          analyticsPerformancePageEnabled && !isCorporate
        }
      />,
    );
    if (currentWorkflowId && workflows[currentWorkflowId]) {
      details.push(
        <AnalyticsWorkflowDetail
          key="analytics-events"
          location={location}
          workflow={workflows[currentWorkflowId]}
          events={events}
        />,
      );
    }
    if (analyticsCohortsEnabled && pathname.startsWith('/analytics/cohorts')) {
      details.push(
        <AnalyticsEngagementCohortsDetail
          key="analytics-cohorts"
          location={location}
          params={params}
        />,
      );
    }
  } else if (pathname.startsWith('/settings') && !appShellSettingsEnabled) {
    details.push(
      <SettingsDetail
        key="settings-details"
        location={location}
        canViewChat={canViewChat}
        canEditSettings={canEditSettings}
        schedulerEnabled={schedulerEnabled}
      />,
    );
  } else if (
    schedulerEnabled &&
    schedulingAllowed &&
    [
      '/meetings/template',
      '/meetings/team',
      '/meetings/settings',
      '/meetings/events',
    ].some((path) => pathname === path) &&
    !genesisEnabled
  ) {
    details.push(
      <SchedulerDetail key="scheduler-detail" location={location} />,
    );
  }

  const shouldExpand = !(canViewChat && pathname.startsWith('/messages'));

  const showDetailsNav = !!details.length;

  return (
    <div className={css.container}>
      <SideNavBar
        onWorkflowClick={handleWorkflowClick}
        onAnalyticsClick={handleAnalyticsClick}
        pathname={pathname}
        showWorkflows={showWorkflows}
        canViewAnalytics={canViewAnalytics}
        canViewAudienceMembers={canViewAudienceMembers}
        canViewWorkflows={canViewWorkflows}
        trmEnabled={trmEnabled}
        canEditSettings={canEditSettings}
        canViewChat={canViewChat}
        canViewChatorEditChatbot={canViewChatorEditChatbot}
        atsBulkWritebackEnabled={atsBulkWritebackEnabled}
        schedulerEnabled={schedulerEnabled}
        schedulingAllowed={schedulingAllowed}
        canViewReferrals={canViewReferrals}
        shouldExpand={shouldExpand}
        expanded={expanded}
        setExpand={setExpand}
        handleSenseUniversityOnClick={handleSenseUniversityOnClick}
      />
      {!hideSupportButton && (
        <ZendeskTrigger className={css.zendeskSupportIcon} />
      )}
      {/* $FlowIssue - children prop as JSX not recognized by FlowType */}
      {showDetailsNav && <div className={css.detailContainer}>{details}</div>}
    </div>
  );
};

const AnalyticsDetail = ({
  workflows,
  analyticsPerformancePageEnabled,
  location,
}: {
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflows: {[id: string]: Workflow},
  location: RouteLocation,
  analyticsPerformancePageEnabled: boolean,
}) => {
  const {pathname} = location;
  const isChatbotEnabled = useSelector(selectAgencyConfig).enableChatbot;
  const [referralsEnabled, canUseReferral] = useReferrals();
  const analyticsV2Enabled = useAnalyticsV2();
  const schedulerEnabled = useSchedulerEnabled();
  const isDiscoverViewEnabled = useReleaseFlag('discoverViewEnabled');
  const discoverViewEnabled = isInternalUser() || isDiscoverViewEnabled;
  const jobMatchEnabled = useJobMatchEnabledForAnyProduct();
  const landingPageView = useDisplayConfig('landing_page_view');
  const isReferralAnalytics = useAgencyConfig('enableReferralV2');
  // [TODO]: Remove this once we have a better way to handle this - One way is useDisplayConfig
  // Discussed with @dhirah [ Analytics ]
  const checkAccessForEmbedding = useApi<boolean>('/sigma/access', {
    apiPath: ANALYTICS_API_V3,
  });

  const isNewAnalyticsJourneyDashboard =
    pathname === '/analytics' && checkAnalyticsNewLandingPageEnabled();

  const analyticsWorkflowList = (
    <AnalyticsWorkflowList key="analytics" workflows={workflows} isAnalytics />
  );

  return (
    <div className={css.detail}>
      {checkAccessForEmbedding.result &&
        checkAnalyticsEmbeddedEnabled() &&
        !isNewAnalyticsJourneyDashboard && (
          <>
            <AnalyticsDetailItem
              icon={<AnalyticsEmbedIcon className={css.topNavIcon} />}
              to="/embedded-landing"
              data-qa-id="select-performance-analytics"
            >
              Analytics <div className={css.analyticsBeta}>Beta</div>
            </AnalyticsDetailItem>
            <div className={css.devider}></div>
          </>
        )}

      <section className={css.detailSection}>
        <div className={css.detailTitle}>Dashboards</div>
        <div className={css.detailItems}>
          {analyticsPerformancePageEnabled && !isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<PerformanceIcon />}
              to="/analytics/performance"
              data-qa-id="select-performance-analytics"
            >
              Performance
            </AnalyticsDetailItem>
          )}
          <AnalyticsDetailItem
            icon={<MetricsIcon />}
            to="/analytics"
            onlyActiveOnIndex
            data-qa-id="select-overall-analytics"
          >
            {analyticsV2Enabled ? 'Overview Metrics' : 'Overall Metrics'}
          </AnalyticsDetailItem>
          {!isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<ResponsesIcon />}
              to="/analytics/responses"
              data-qa-id="select-responses-analytics"
            >
              Responses
            </AnalyticsDetailItem>
          )}
          {!isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<FlagIcon />}
              to="/analytics/flagged-responses"
              data-qa-id="select-flagged-analytics"
            >
              Flagged Responses
            </AnalyticsDetailItem>
          )}
          {analyticsV2Enabled && !isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<DeliveryHistoryIcon className={css.deliveryHistory} />}
              to="/analytics/delivery-history"
            >
              Delivery History
            </AnalyticsDetailItem>
          )}
          {!isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<ClicksIcon />}
              to="/analytics/clicks"
              data-qa-id="select-clicks-analytics"
            >
              External Link Clicks
            </AnalyticsDetailItem>
          )}
          {isChatbotEnabled && !isNewAnalyticsJourneyDashboard && (
            <>
              <AnalyticsDetailItem
                icon={<ChatbotAnalyticsIcon />}
                to="/analytics/chatbot"
                data-qa-id="select-chatbot-analytics"
              >
                Chatbot Metrics
              </AnalyticsDetailItem>
              <AnalyticsDetailItem
                icon={<ChatbotTranscriptsIcon />}
                to="/analytics/transcripts"
                data-qa-id="select-chatbot-transcripts"
              >
                Transcripts
              </AnalyticsDetailItem>
            </>
          )}
          {!isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<MessagesAnalyticsIcon />}
              to="/analytics/messages/outbound"
              data-qa-id="select-messages-analytics"
            >
              Messages
            </AnalyticsDetailItem>
          )}
          {referralsEnabled &&
            canUseReferral &&
            !isNewAnalyticsJourneyDashboard && (
              <AnalyticsDetailItem
                icon={<AnalyticsReferralIcon />}
                to="/analytics/referral"
                data-qa-id="select-referral-analytics"
              >
                Referrals
              </AnalyticsDetailItem>
            )}
          {schedulerEnabled && !isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<MeetingsAnalyticsIcon />}
              to="/analytics/meetings"
            >
              Meetings
            </AnalyticsDetailItem>
          )}
          {!isNewAnalyticsJourneyDashboard && (
            <AnalyticsDetailItem
              icon={<NpsIcon />}
              to="/analytics/nps"
              data-qa-id="select-nps-analytics"
            >
              NPS
            </AnalyticsDetailItem>
          )}
          {jobMatchEnabled &&
            discoverViewEnabled &&
            !isNewAnalyticsJourneyDashboard && (
              <AnalyticsDetailItem
                icon={<JobMatchesIcon />}
                to="/analytics/discover"
                data-qa-id="select-job-match-analytics"
              >
                Job Matches
              </AnalyticsDetailItem>
            )}
          {referralsEnabled &&
            canUseReferral &&
            !isNewAnalyticsJourneyDashboard && (
              <AnalyticsDetailItem
                icon={<AnalyticsReferralIcon />}
                to="/analytics/referral"
                data-qa-id="select-referral-analytics"
              >
                Referrals
              </AnalyticsDetailItem>
            )}
        </div>
      </section>
      {checkAnalyticsNewLandingPageEnabled()
        ? isNewAnalyticsJourneyDashboard
          ? analyticsWorkflowList
          : null
        : analyticsWorkflowList}
    </div>
  );
};

const AnalyticsWorkflowDetail = ({
  location,
  workflow,
  events,
}: {
  location: RouteLocation,
  // $FlowFixMe[value-as-type] [v1.32.0]
  workflow: Workflow,
  // $FlowFixMe[value-as-type] [v1.32.0]
  events: ?(Event[]),
}) => {
  const analyticsV2Enabled = useAnalyticsV2();
  const isChatbotEnabled = useSelector(selectAgencyConfig).enableChatbot;
  const basePath = '/analytics/journey/' + workflow.id;

  return (
    <div className={css.detail}>
      <Link
        className={css.detailWorkflowTitle}
        to={{
          ...location,
          pathname: `/analytics`,
        }}
      >
        <ArrowLeft className={css.backArrow} />
        <TruncatedText text={workflow.name} limit={25} />
      </Link>

      <section className={css.detailSectionEvents}>
        <div className={css.detailTitleEvents}>Dashboards</div>
        <div className={css.detailItems}>
          <Link
            className={css.detailItemEvents}
            activeClassName={css.detailEventOn}
            onlyActiveOnIndex
            to={{
              ...location,
              pathname: basePath,
            }}
          >
            <MetricsIcon className={css.metricsIcon} />
            {analyticsV2Enabled ? 'Overview Metrics' : 'Metrics'}
          </Link>

          <EventDetailItem
            icon={<ResponsesIcon />}
            to={basePath + '/responses'}
          >
            Responses
          </EventDetailItem>

          <EventDetailItem
            icon={<FlagIcon />}
            to={basePath + '/flagged-responses'}
          >
            Flagged Responses
          </EventDetailItem>

          {analyticsV2Enabled && (
            <EventDetailItem
              icon={<DeliveryHistoryIcon className={css.deliveryHistory} />}
              to={`${basePath}/delivery-history`}
            >
              Delivery History
            </EventDetailItem>
          )}

          <EventDetailItem icon={<ClicksIcon />} to={basePath + '/clicks'}>
            Clicks
          </EventDetailItem>

          {isChatbotEnabled && (
            <>
              <EventDetailItem
                icon={<ChatbotAnalyticsIcon />}
                to={basePath + '/chatbot'}
              >
                Chatbot Metrics
              </EventDetailItem>
              <EventDetailItem
                icon={<ChatbotTranscriptsIcon />}
                to={basePath + '/transcripts'}
              >
                Transcripts
              </EventDetailItem>
            </>
          )}

          <EventDetailItem
            icon={<EngagementIcon />}
            to={basePath + '/engagement'}
          >
            Engagement
          </EventDetailItem>
        </div>
      </section>

      <section className={css.detailSectionEvents}>
        <div className={css.detailTitleEvents}>Touchpoints</div>
        <div className={css.detailEvents}>
          {events ? (
            events.map((event) => (
              <TruncatedText
                text={event.title}
                limit={25}
                component={Link}
                className={css.detailEvent}
                activeClassName={css.detailEventOn}
                to={{
                  ...location,
                  pathname: `${basePath}/touchpoint/${event.id}`,
                }}
                key={event.id}
              />
            ))
          ) : (
            <Loading />
          )}
        </div>
      </section>
    </div>
  );
};

const _SettingsDetail = ({
  location,
  canViewChat,
  canViewChatDev,
  canEditPermissions,
  canEditSettings,
  schedulerEnabled,
  entities,
  emailDNSV2Enabled,
}: {
  location: RouteLocation,
  canViewChat: boolean,
  canViewChatDev: boolean,
  canEditPermissions: boolean,
  canEditSettings: boolean,
  schedulerEnabled: boolean,
  entities: AtsEntity[],
  emailDNSV2Enabled: boolean,
}) => {
  const [referralsEnabled, canUseReferral] = useReferrals();
  const j2Enabled = useJ2Enabled();

  const isChatbotEnabled = useSelector(selectEnableChatbot);
  const hasChatbotPermissions = usePrivilege('EDIT_CHATBOT');
  const hasMessagingPermissions = usePrivilege('USE_MESSAGING');

  const canManageSecurity = usePrivilege('MANAGE_SECURITY');
  const textTemplatesEnabled = useReleaseFlag('messagingTemplatesTable');
  const useGenesisUIForAuthentication = useReleaseFlag(
    'genesisUiForAuthentication',
  );

  const isCalendarAccountSettingsEnabled = usePrivilege(
    'MANAGE_TEAM_TEMPLATES',
  );

  const isHolidayManagementEnabled = useSchedulerHolidyManagement();
  const seesHolidayManagementLink =
    isHolidayManagementEnabled &&
    isCalendarAccountSettingsEnabled &&
    schedulerEnabled;
  const {labelI18nInstance} = useI18n();
  const isTRMCustomer = useDisplayConfig('is_trm_customer'); // Only showing "Accounts" section for TRM customers
  const agencyConfig = useSelector(selectAgencyConfig);

  const showEntity = (name) => {
    if (
      name === 'ae_client_company_employee' &&
      agencyConfig.customerBusinessType === 'corporate'
    ) {
      // Similarly need to hard code contact entity type of all other ATSs, this is not a good approach. Should be handled from the backend.
      return false;
    }
    return true;
  };

  // FIXME(marcos): check here for chat+messaging permissions as well
  const hasLATPermissions =
    hasMessagingPermissions && isChatbotEnabled && hasChatbotPermissions;
  const seesLATQueueSettings = useLiveAgentTransfer() && hasLATPermissions;

  return (
    <div className={css.detail}>
      {!isTRMCustomer && (
        <section className={css.detailSection}>
          <div className={css.detailTitle}>Settings</div>
          <div className={css.detailItems}>
            {canEditSettings && (
              <>
                <Link
                  className={css.detailItem}
                  activeClassName={css.detailItemOn}
                  onlyActiveOnIndex={true}
                  to={{
                    ...location,
                    pathname: '/settings',
                  }}
                >
                  <SettingsIcon className={css.settingsDetailIcon} />
                  General
                </Link>
                {j2Enabled && (
                  <Link
                    className={css.detailItem}
                    activeClassName={css.detailItemOn}
                    onlyActiveOnIndex={true}
                    to={{
                      ...location,
                      pathname: '/settings/blackout-windows',
                    }}
                  >
                    <CommunicationsIcon className={css.settingsDetailIcon} />
                    Communication Windows
                  </Link>
                )}
                <Link
                  className={css.detailItem}
                  activeClassName={css.detailItemOn}
                  onlyActiveOnIndex={true}
                  to={{
                    ...location,
                    pathname: '/settings/brand',
                  }}
                >
                  <BrandingIcon className={css.settingsDetailIcon} />
                  Branding
                </Link>
                <Link
                  className={css.detailItem}
                  activeClassName={css.detailItemOn}
                  onlyActiveOnIndex={true}
                  to={{
                    ...location,
                    pathname: '/settings/ats-mapping',
                  }}
                >
                  <AtsMappingIcon className={css.settingsDetailIcon} />
                  ATS Mapping
                </Link>
                <Link
                  className={css.detailItem}
                  activeClassName={css.detailItemOn}
                  to={{
                    ...location,
                    pathname: '/settings/templates',
                  }}
                >
                  <TemplateIcon className={css.settingsDetailIcon} />
                  {labelI18nInstance.t('email_templates', 'Templates')}
                </Link>
              </>
            )}

            {textTemplatesEnabled && (
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{
                  ...location,
                  pathname: '/settings/text-templates',
                }}
              >
                <SmartphoneIcon className={css.settingsDetailIcon} />
                Text Templates
              </Link>
            )}
            {canEditSettings && (
              <>
                <Link
                  className={css.detailItem}
                  activeClassName={css.detailItemOn}
                  to={{
                    ...location,
                    pathname: '/settings/global-variables',
                  }}
                >
                  <GlobalVariableIcon className={css.settingsDetailIcon} />
                  Global Variables
                </Link>
                <Link
                  className={css.detailItem}
                  activeClassName={css.detailItemOn}
                  to={{
                    ...location,
                    pathname: '/settings/flagged-words',
                  }}
                  data-qa-id="flagging"
                >
                  <FlagIcon className={css.settingsDetailIcon} />
                  Flagging
                </Link>
                {canViewChatDev && canViewChat && (
                  <Link
                    className={css.detailItem}
                    activeClassName={css.detailItemOn}
                    to={{
                      ...location,
                      pathname: '/settings/phones',
                    }}
                  >
                    <PhoneIcon className={css.settingsDetailIcon} />
                    Phone Numbers
                  </Link>
                )}
                {agencyConfig.customerBusinessType !== 'corporate' && (
                  <Link
                    className={css.detailItem}
                    activeClassName={css.detailItemOn}
                    to={{
                      ...location,
                      pathname: '/settings/ats/performance',
                    }}
                  >
                    <PerformanceIcon className={css.performanceIcon} />
                    Performance
                  </Link>
                )}

                {emailDNSV2Enabled && (
                  <Link
                    className={css.detailItem}
                    activeClassName={css.detailItemOn}
                    to={{
                      ...location,
                      pathname: '/settings/email-dns',
                    }}
                  >
                    <EmailIcon className={css.settingsDetailIcon} />
                    Email DNS
                  </Link>
                )}
              </>
            )}
            <Link
              className={css.detailItem}
              activeClassName={css.detailItemOn}
              to={{
                ...location,
                pathname: '/settings/reports',
              }}
            >
              <ReportsIcon className={css.settingsDetailIcon} />
              Reports
            </Link>
            {canEditSettings && (
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{
                  ...location,
                  pathname: '/settings/content-subscription/category',
                }}
              >
                <ContentSubscriptionIcon className={css.settingsDetailIcon} />
                User Content Subscription
              </Link>
            )}
            {referralsEnabled && canUseReferral && (
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{
                  ...location,
                  pathname: '/settings/referrals',
                }}
              >
                <ReferralGlyphIcon className={css.settingsDetailIcon} />
                Referrals
              </Link>
            )}
            {isCalendarAccountSettingsEnabled && schedulerEnabled && (
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{...location, pathname: '/settings/calendar-sync'}}
              >
                <BlankCalendarIcon className={css.settingsDetailIcon} />
                Calendar Sync{' '}
              </Link>
            )}
            {seesHolidayManagementLink && (
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{...location, pathname: '/settings/holidays'}}
              >
                <TadaIcon className={css.settingsDetailIcon} />
                Holidays{' '}
              </Link>
            )}

            {seesLATQueueSettings && (
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{...location, pathname: '/settings/live-agent-transfer'}}
              >
                <Icon
                  className={css.settingsDetailIcon}
                  color="secondary"
                  name="headset"
                  size="medium"
                  type="regular"
                />
                Live Chat{' '}
              </Link>
            )}
          </div>
        </section>
      )}

      {canEditSettings && (
        <>
          <section className={css.detailSection}>
            <div className={css.detailTitle}>Accounts</div>
            <div className={css.detailItems}>
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{
                  ...location,
                  pathname: '/settings/accounts',
                  query: {...location.query, search: ''},
                }}
              >
                <UsersIcon className={css.settingsDetailIcon} />
                Users
              </Link>
              <Link
                className={css.detailItem}
                activeClassName={css.detailItemOn}
                to={{
                  ...location,
                  pathname: '/settings/numbers',
                  query: {...location.query, search: ''},
                }}
              >
                <SettingsNumberIcon className={css.settingsDetailIcon} />
                Numbers
              </Link>
              {canEditPermissions && (
                <React.Fragment>
                  <Link
                    className={css.detailItem}
                    activeClassName={css.detailItemOn}
                    to={{
                      ...location,
                      pathname: '/settings/roles',
                    }}
                  >
                    <SettingsRolesIcon className={css.settingsDetailIcon} />
                    Roles
                  </Link>
                  <Link
                    className={css.detailItem}
                    activeClassName={css.detailItemOn}
                    to={{
                      ...location,
                      pathname: '/settings/groups',
                    }}
                    data-qa-id="groups-setting-link"
                  >
                    <SettingsGroupsIcon className={css.settingsDetailIcon} />
                    Groups
                  </Link>
                  {useGenesisUIForAuthentication && canManageSecurity && (
                    <Link
                      className={css.detailItem}
                      activeClassName={css.detailItemOn}
                      to={{
                        ...location,
                        pathname: '/settings/security',
                      }}
                    >
                      <ProgramsSettingsIcon
                        className={css.settingsDetailIcon}
                      />
                      Security
                    </Link>
                  )}
                  {!isTRMCustomer && (
                    <Link
                      className={css.detailItem}
                      activeClassName={css.detailItemOn}
                      to={{
                        ...location,
                        pathname: '/settings/communication-preference',
                      }}
                    >
                      <ClientContactIcon className={css.settingsDetailIcon} />
                      Communication Preferences
                    </Link>
                  )}
                </React.Fragment>
              )}
            </div>
          </section>
          {canEditPermissions && !isTRMCustomer && (
            <TearsheetsSettingsLink
              location={location}
              canEditPermissions={canEditPermissions}
            />
          )}
          {!isTRMCustomer && (
            <section className={css.detailSection}>
              <div className={css.detailTitle}>ATS Fields</div>
              {entities.map(
                ({name, display_name}) =>
                  showEntity(name) && (
                    <Link
                      className={css.detailItem}
                      activeClassName={css.detailItemOn}
                      to={{
                        ...location,
                        pathname: `/settings/ats/fields/${name}`,
                      }}
                    >
                      <AtsFieldsIcon className={css.settingsDetailIcon} />
                      {titleCase(display_name)}
                    </Link>
                  ),
              )}
            </section>
          )}
        </>
      )}
    </div>
  );
};

const TearsheetsSettingsLink = ({
  location,
  canEditPermissions,
}: {
  location: RouteLocation,
  canEditPermissions: boolean,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    canEditPermissions && dispatch(getAllowedTearsheetTypes());
  }, [canEditPermissions]);

  const allowedTearsheetTypes: AllowedTearsheetTypes = useSelector(
    (state) => state.atsSettings.allowedTearsheetTypes,
  );

  return (
    allowedTearsheetTypes?.length !== 0 && (
      <section className={css.detailSection}>
        <div className={css.detailTitle}>ATS Syncs</div>
        <div className={css.detailItems}>
          <Link
            className={css.detailItem}
            activeClassName={css.detailItemOn}
            key="tearsheets"
            to={{
              ...location,
              pathname: '/settings/ats/tearsheets',
            }}
          >
            <TemplateIcon className={css.settingsDetailIcon} />
            Tearsheets
          </Link>
        </div>
      </section>
    )
  );
};

const SettingsDetail = connect((state) => ({
  canEditPermissions: hasPrivilege(state, EDIT_PERMISSIONS),
  canViewChatDev: canUseChatPreRelease(state),
  entities: getAtsEntityMappings(state),
  emailDNSV2Enabled: selectDNSV2Enabled(state),
}))(_SettingsDetail);

function DetailItem({
  children,
  className,
  activeClassName = css.detailItemOn,
  icon,
  // $FlowFixMe[incompatible-use]
  ...props
}: React.ElementConfig<typeof Link> & {
  icon: React.Node,
  ...
}) {
  return (
    // $FlowFixMe[cannot-spread-inexact]
    <Link
      className={className || css.detailItem}
      activeClassName={activeClassName}
      {...props}
    >
      <div className={css.detailIcon}>{icon}</div>
      {children}
    </Link>
  );
}

function AnalyticsDetailItem({to, ...props}) {
  return (
    <DetailItem {...props} to={(location) => ({...location, pathname: to})} />
  );
}

function EventDetailItem(props) {
  return (
    <AnalyticsDetailItem
      {...props}
      className={css.detailItemEvents}
      activeClassName={css.detailEventOn}
    />
  );
}

function SideNavBar(props: {
  onWorkflowClick: (SyntheticEvent<>) => mixed,
  onAnalyticsClick: (SyntheticEvent<>) => mixed,
  expand?: mixed,
  // NOTE (kyle): below props needed for lifecylce hooks
  pathname: string,
  showWorkflows?: mixed,
  canViewAnalytics: boolean,
  canViewAudienceMembers: boolean,
  canViewWorkflows: boolean,
  trmEnabled: boolean,
  canEditSettings: boolean,
  canViewChat: boolean,
  canViewChatorEditChatbot: boolean,
  atsBulkWritebackEnabled: boolean,
  schedulerEnabled: boolean,
  schedulingAllowed: boolean,
  canViewReferrals: boolean,
  shouldExpand: boolean,
  setExpand: (boolean) => void,
  expanded: boolean,
  handleSenseUniversityOnClick: () => void,
}) {
  const {
    onWorkflowClick,
    onAnalyticsClick,
    expand,
    canViewAnalytics,
    canViewAudienceMembers,
    canViewWorkflows,
    trmEnabled,
    canEditSettings,
    canViewChat,
    canViewChatorEditChatbot,
    atsBulkWritebackEnabled,
    schedulerEnabled,
    schedulingAllowed,
    canViewReferrals,
    shouldExpand,
    setExpand,
    expanded,
    handleSenseUniversityOnClick,
  } = props;

  const history = useHistory();
  const {location} = history;

  const selectedKey = getSelectedKey(location.pathname);

  const isTRMCustomer = useDisplayConfig('is_trm_customer');

  React.useEffect(() => {
    //setExpand(false);
  }, [props.pathname, props.showWorkflows]);

  const handleMouseEnter = () => {
    //setExpand(true);
  };

  const handleMouseLeave = () => {
    //setExpand(false);
  };

  const isChatbotEnabled = useChatbotEnabled();
  const showSenseEvents = useSenseEvents();
  const isChatbotAllowed = usePrivilege('EDIT_CHATBOT');
  const [referralsEnabled, canUseReferral] = useReferrals();
  const referralV2 = useAgencyConfig('enableReferralV2');
  const analyticsDefaultPageEnabled = useReleaseFlag(
    'analyticsDefaultPageEnabled',
  );

  const isNewCrmUiEnabled = useReleaseFlag('crm_redesign_enabled');

  const {labelI18nInstance} = useI18n();
  const landingPageView = useDisplayConfig('landing_page_view');
  const jobsListView = useDisplayConfig('jobs_list_enabled');
  const canViewAutomationWorkflows = useDisplayConfig('aep_workflow_view');
  const j2Enabled = useJ2Enabled();

  const customerBusinessType =
    useSelector(selectAgencyConfig).customerBusinessType;
  const isCorporate = customerBusinessType === 'corporate';

  const isPageHaveViewPrivilege = usePrivilege('VIEW_PAGES');
  const isPageHaveAdminPrivilege = usePrivilege('ADMIN_PAGES');
  const isPageHaveEditPrivilege = usePrivilege('EDIT_PAGES');

  const pagessHaveNoAccess =
    isPageHaveViewPrivilege ||
    isPageHaveAdminPrivilege ||
    isPageHaveEditPrivilege;

  //const selectedKey = getSelectedSideNav();

  const handleSideMenuChange = (e, pageNameKey = '') => {
    if (pageNameKey === 'dashboard') {
      history.push('/dashboard');
    } else if (pageNameKey === 'journeys') {
      history.push('/journeys');
      // Note: Just carrying over existing feature. Don't know why it is here.
      // $FlowFixMe[incompatible-call]
      onWorkflowClick(e);
    } else if (pageNameKey === 'analytics') {
      // $FlowFixMe[incompatible-call]
      onAnalyticsClick(e);
      if (checkAnalyticsNewLandingPageEnabled()) {
        history.push('/reports');
      } else if (!isCorporate && analyticsDefaultPageEnabled) {
        history.push('/analytics/performance');
      } else if (!canViewAnalytics && canViewChat) {
        history.push('/analytics/messages/outbound');
      } else {
        history.push('/analytics');
      }
    } else if (pageNameKey === 'configuration') {
      if (isTRMCustomer) {
        history.push('/settings/accounts');
      } else if (canEditSettings) {
        history.push('/settings');
      } else {
        history.push('/settings/reports');
      }
    } else if (pageNameKey === 'referral' && !!referralV2 && canViewReferrals) {
      history.push('/referralv2');
    } else if (pageNameKey.includes('.')) {
      const path = pageNameKey.replace(/\./g, '/');
      history.push(`/${path}`);
    } else {
      history.push(`/${pageNameKey}`);
    }
  };

  const handleSubLinkSelect = (key) => {
    handleSideMenuChange({}, key);
  };

  return (
    <div
      className={classify(css.sideNavContainer, {[css.collapsed]: !expanded})}
    >
      <SideNavHeader expanded={expanded} setExpand={setExpand} />

      <div className={css.sideMenuLinkWrap}>
        {canViewAnalytics && (
          <SideMenuLink
            onChange={handleSideMenuChange}
            opened={expanded}
            pageNameKey="dashboard"
            selectedValue={selectedKey}
          />
        )}

        {(canViewAnalytics || (!canViewAnalytics && canViewChat)) && (
          <SideMenuLink
            onChange={handleSideMenuChange}
            opened={expanded}
            pageNameKey="analytics"
            selectedValue={selectedKey}
          />
        )}

        {(canViewWorkflows ||
          (schedulerEnabled && schedulingAllowed) ||
          (isChatbotEnabled && isChatbotAllowed)) && (
          <SideMenuGroup
            sideMenuLinkKey="automation"
            subLinkOptions={[
              ...(canViewWorkflows
                ? [
                    {
                      key: 'journeys',
                      label: 'Journeys',
                    },
                  ]
                : []),
              ...(canViewWorkflows && canViewAutomationWorkflows && j2Enabled
                ? [
                    {
                      key: 'automation-workflows',
                      label: 'Workflows',
                    },
                  ]
                : []),
              ...(schedulerEnabled && schedulingAllowed
                ? [
                    {
                      key: 'meetings',
                      label: 'Scheduling',
                    },
                  ]
                : []),
              ...(isChatbotEnabled && isChatbotAllowed
                ? [
                    {
                      key: 'conversations',
                      label: 'Chatbot',
                    },
                  ]
                : []),
              ...(canViewWorkflows && atsBulkWritebackEnabled
                ? [
                    {
                      key: 'database-cleanup',
                      label: 'Bulk Edit',
                    },
                  ]
                : []),
            ]}
            selectedValue={selectedKey}
            expanded={expanded}
            onSubLinkSelect={handleSubLinkSelect}
          />
        )}

        {canViewAudienceMembers && (
          <SideMenuGroup
            sideMenuLinkKey="people"
            subLinkOptions={[
              {
                key: 'people.candidates',
                label: 'All People',
              },
              {
                key: 'people.lists',
                label: 'Lists',
              },
              {
                key: 'people.contacts',
                label: 'Contacts',
              },
              {
                key: 'people.leads',
                label: 'Leads',
              },
            ]}
            selectedValue={selectedKey}
            expanded={expanded}
            onSubLinkSelect={handleSubLinkSelect}
          />
        )}

        {jobsListView && (
          <SideMenuGroup
            sideMenuLinkKey="jobs"
            subLinkOptions={[
              {
                key: 'jobs.all',
                label: 'All Jobs',
              },
            ]}
            selectedValue={selectedKey}
            expanded={expanded}
            onSubLinkSelect={handleSubLinkSelect}
          />
        )}

        {/* Enable before releasing to prod @swatantra */}
        {trmEnabled && isNewCrmUiEnabled && (
          <SideMenuGroup
            sideMenuLinkKey="jobs"
            subLinkOptions={[
              {
                key: 'crm.jobs',
                label: 'Crm Jobs',
              },
            ]}
            selectedValue={selectedKey}
            expanded={expanded}
            onSubLinkSelect={handleSubLinkSelect}
          />
        )}

        {trmEnabled && (
          <SideMenuLink
            onChange={handleSideMenuChange}
            opened={expanded}
            pageNameKey="trm"
            selectedValue={selectedKey}
          />
        )}

        {checkJdGeneratorEnabled() && (
          <SideMenuLink
            onChange={handleSideMenuChange}
            opened={expanded}
            pageNameKey="sense-jd-ai"
            selectedValue={selectedKey}
          />
        )}

        {showSenseEvents && (
          <SideMenuLink
            onChange={handleSideMenuChange}
            opened={expanded}
            pageNameKey="drive"
            selectedValue={selectedKey}
          />
        )}

        {((referralsEnabled && canUseReferral) ||
          (!!referralV2 && canViewReferrals) ||
          (landingPageView && pagessHaveNoAccess) ||
          (schedulerEnabled && schedulingAllowed)) && (
          <SideMenuGroup
            sideMenuLinkKey="sourcing"
            subLinkOptions={[
              ...((referralsEnabled && canUseReferral) ||
              (!!referralV2 && canViewReferrals)
                ? [
                    {
                      key: 'referral',
                      label: 'Referrals',
                    },
                  ]
                : []),
              ...(landingPageView && pagessHaveNoAccess
                ? [
                    {
                      key: 'pages',
                      label: 'Pages',
                    },
                    {
                      key: 'pages.sites',
                      label: 'Career Sites',
                    },
                  ]
                : []),
            ]}
            selectedValue={selectedKey}
            expanded={expanded}
            onSubLinkSelect={handleSubLinkSelect}
          />
        )}

        {canViewChatorEditChatbot && (
          <SideMenuGroup
            sideMenuLinkKey="messaging"
            subLinkOptions={[
              {
                label: 'Messaging',
                key: 'messages',
              },
              {
                key: 'contacts',
                label: 'Contacts',
              },
            ]}
            selectedValue={selectedKey}
            expanded={expanded}
            onSubLinkSelect={handleSubLinkSelect}
          />
        )}

        <SideMenuLink
          onChange={handleSideMenuChange}
          opened={expanded}
          pageNameKey="configuration"
          selectedValue={selectedKey}
          data-qa-id="select-settings-page"
        />
      </div>
      <div className={css.stickyBottom}>
        <AccountBar
          expanded={expanded}
          handleSenseUniversityOnClick={handleSenseUniversityOnClick}
        />
      </div>
    </div>
  );
}
SideNavBar.displayName = 'SideNavBar';

function SchedulerDetail({location}) {
  const isTeamTemplater = usePrivilege('MANAGE_TEAM_TEMPLATES');
  const showScheduledEvents = useReleaseFlag('schedulerEnableScheduledEvents');
  return (
    <div className={css.detail}>
      <section className={css.detailSection}>
        <div className={css.detailTitle}>Meetings</div>
        <div className={css.detailItems}>
          {showScheduledEvents && (
            <Link
              className={css.detailItem}
              activeClassName={css.detailItemOn}
              onlyActiveOnIndex={true}
              to={{
                ...location,
                pathname: '/meetings/events',
              }}
            >
              <CalendarStarIcon className={css.settingsDetailIcon} />
              Scheduled Events
            </Link>
          )}
          <Link
            className={css.detailItem}
            activeClassName={css.detailItemOn}
            onlyActiveOnIndex={true}
            to={{
              ...location,
              pathname: '/meetings/template',
            }}
          >
            <CalendarMenuIcon className={css.settingsDetailIcon} />
            My Templates
          </Link>
          {isTeamTemplater && (
            <Link
              className={css.detailItem}
              activeClassName={css.detailItemOn}
              to={{
                ...location,
                pathname: '/meetings/team',
              }}
            >
              <CalendarAddIcon className={css.settingsDetailIcon} />
              Team Templates
            </Link>
          )}
          <Link
            className={css.detailItem}
            activeClassName={css.detailItemOn}
            onlyActiveOnIndex={true}
            to={{
              ...location,
              pathname: '/meetings/settings',
            }}
          >
            <SettingsIcon className={css.settingsDetailIcon} />
            Settings
          </Link>
        </div>
      </section>
    </div>
  );
}
