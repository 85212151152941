// @flow strict
import type {MenuOption} from '@spaced-out/ui-design-system/lib/components/Menu';
import moment from 'moment';
import type {
  JOB_INFORMATION,
  JOB_LISTS,
  JOB_CUSTOM_FIELDS,
  MILESTONES,
  INTERVIEW_FLOW,
  JOB_STAGES,
  JOB_HIRING_ROUNDS,
  JOB_HIRING_TEAM,
  STAGE_REMOVE_RESPONSE,
  JOB_LOCATION,
  USER_BASE_INFO,
  PENDING_APPROVALS,
  CompanyInfoJdGen,
  ApprovalObject,
  EmployeeRole,
  JOB_HIRING_STEPS,
  JOB_SCORE_CARDS,
  SCORE_CARD_CONFIG_RESPONSE,
  FEEDBACK_CONFIG_RESPONSE,
  STEP_QUESTIONNAIRE_SECTIONS_RESPONSE,
  VALIDATED_OBJECT,
  CandidateList,
  ApprovalStatus,
  Tag,
  HIERARCHY_LEVEL,
} from 'src/types/crm';

// action constants
export const UPDATE_JOB_INFORMATION = 'update-job-information';
export const UPDATE_APPROVALS = 'update-approvals';
export const GET_JOB_LOCATIONS = 'get-job-location';
export const GET_USER_INFO = 'get-base-info';
export const GET_PENDING_APPROVALS = 'get-pending-approvals';
export const GET_JOB_GRADES = 'get-job-grade';
export const GET_JOB_DEPARTMENTS = 'get-job-department';
export const GET_CUSTOM_JOB_FIELDS = 'get-custom-job-fields';
export const GET_HIRING_MILESTONES = 'get-milestones';
export const GET_JOB_INTERVIEW_FLOW = 'get-job-interview-flow';
export const GET_JOB_HIRING_STAGES = 'get-job-hiring-stages';
export const CREATE_HIRING_ROUNDS = 'create-hiring-rounds';
export const GET_HIRING_TEAM = 'get-hiring-team';
export const REMOVE_HIRING_STAGE = 'remove-hiring-stage';
export const UPDATE_COMPANY_INFO_JD_GENERATE = 'update-company-jd-generate';
export const GET_EMPLOYEE_ROLE = 'get-employee-role';
export const GET_JOB_STEPS = 'get-job-steps';
export const GET_JOB_SCORE_CARDS = 'get-job-score-cards';
export const GET_SCORE_CARD_CONFIG = 'get-scorecard-config';
export const UPDATE_SCORE_CARD_CONFIG = 'update-scorecard-config';
export const GET_FEEDBACK_CONFIG = 'get-feedback-config';
export const GET_STEP_QUESTIONNAIRE_SECTIONS = 'get-step-questionnaire';
export const POST_INTERNAL_FORM_CONFIG_EMPLOYEE =
  'post_internal_form_config_employee';
export const POST_INTERNAL_FORM_CONFIG_SEND_EMAIL_STATUS =
  'post_internal_form_config_send_email_status';
export const POST_INTERNAL_FORM_CONFIG_FORM_SELECTION =
  'post_internal_form_config_form_selection';
export const GET_APPROVAL_REQUEST_TIME = 'get-approval-request-time';
export const SELECTED_QUESTIONNAIRE_SECTION = 'selected_questionnaire_section';
export const GET_JOB_MATCHES = 'get-job-matches';
export const UPDATE_APPROVAL_STATUS = 'update-approval-status';
export const GET_TAGS = 'get-tags';
export const EDIT_TAG = 'edit-tag';
export const DELETE_TAG = 'delete-tag';
export const ADD_TAGS_TO_CANDIDATE = 'add-tags-to-candidate';
export const GET_HIERARCHY_LEVELS = 'get-hierarchy-levels';

//action types
export type UpdateJobInformationAction = {
  type: typeof UPDATE_JOB_INFORMATION,
  payload: JOB_INFORMATION,
};

export type UpdateApprovals = {
  type: typeof UPDATE_APPROVALS,
  payload: Array<ApprovalObject>,
};

export type UpdateApprovalStatusForJob = {
  type: typeof UPDATE_APPROVAL_STATUS,
  payload: ApprovalStatus,
};

export type RequestApprovalTime = {
  type: typeof GET_APPROVAL_REQUEST_TIME,
  payload: string,
};

export type GetJobGradeListAction = {
  type: typeof GET_JOB_GRADES,
  payload: JOB_LISTS[],
};

export type GetJobLocationListAction = {
  type: typeof GET_JOB_LOCATIONS,
  payload: JOB_LOCATION[],
};

export type GetUserInfoAction = {
  type: typeof GET_USER_INFO,
  payload: USER_BASE_INFO,
};

export type GetPendingApprovals = {
  type: typeof GET_PENDING_APPROVALS,
  payload: PENDING_APPROVALS,
};

export type GetJobDepartmentListAction = {
  type: typeof GET_JOB_DEPARTMENTS,
  payload: JOB_LISTS[],
};

export type GetHiringMilestineAction = {
  type: typeof GET_HIRING_MILESTONES,
  payload: MILESTONES[],
};
export type GetCustomJobFiledsAction = {
  type: typeof GET_CUSTOM_JOB_FIELDS,
  payload: JOB_CUSTOM_FIELDS[],
};

export type GetJobInterviewFlowAction = {
  type: typeof GET_JOB_INTERVIEW_FLOW,
  payload: INTERVIEW_FLOW,
};

export type GetJobStageAction = {
  type: typeof GET_JOB_HIRING_STAGES,
  payload: JOB_STAGES,
};

export type CreateHiringRoundsAction = {
  type: typeof CREATE_HIRING_ROUNDS,
  payload: JOB_HIRING_ROUNDS,
};

export type GetHiringTeamAction = {
  type: typeof GET_HIRING_TEAM,
  payload: JOB_HIRING_TEAM,
};

export type RemoveHiringStageAction = {
  type: typeof REMOVE_HIRING_STAGE,
  payload: STAGE_REMOVE_RESPONSE,
};
export type UpdateCompanyInfoJdGenerateAction = {
  type: typeof UPDATE_COMPANY_INFO_JD_GENERATE,
  payload: CompanyInfoJdGen,
};

export type GetEmployeeRoleAction = {
  type: typeof GET_EMPLOYEE_ROLE,
  payload: EmployeeRole[],
};

export type GetJobStepsAction = {
  type: typeof GET_JOB_STEPS,
  payload: JOB_HIRING_STEPS[],
};

export type GetJobScoreCardAction = {
  type: typeof GET_JOB_SCORE_CARDS,
  payload: JOB_SCORE_CARDS,
};

export type GetScoreCardConfigAction = {
  type: typeof GET_SCORE_CARD_CONFIG,
  payload: SCORE_CARD_CONFIG_RESPONSE[],
};

export type UpdateScoreCardConfigAction = {
  type: typeof UPDATE_SCORE_CARD_CONFIG,
  payload: SCORE_CARD_CONFIG_RESPONSE,
};

export type GetFeedbackConfigAction = {
  type: typeof GET_FEEDBACK_CONFIG,
  payload: FEEDBACK_CONFIG_RESPONSE,
};

export type GetStepQuestionnaireSectionAction = {
  type: typeof GET_STEP_QUESTIONNAIRE_SECTIONS,
  payload: STEP_QUESTIONNAIRE_SECTIONS_RESPONSE[],
};

export type PostInternalFormConfigEmployeeAction = {
  type: typeof POST_INTERNAL_FORM_CONFIG_EMPLOYEE,
};

export type PostInternalFormConfigSendEmailStatusAction = {
  type: typeof POST_INTERNAL_FORM_CONFIG_SEND_EMAIL_STATUS,
};

export type PostInternalFormConfigFormSelectionAction = {
  type: typeof POST_INTERNAL_FORM_CONFIG_FORM_SELECTION,
};

export type SelectedQuestionnaireAction = {
  type: typeof SELECTED_QUESTIONNAIRE_SECTION,
  payload: JOB_STAGES,
};
export type GetJobMatchesAction = {
  type: typeof GET_JOB_MATCHES,
  payload: CandidateList,
};

export type GetTagsAction = {
  type: typeof GET_TAGS,
  payload: Tag[],
};

export type EditTagAction = {
  type: typeof EDIT_TAG,
  payload: Tag,
};

export type DeleteTagAction = {
  type: typeof DELETE_TAG,
  payload: Tag,
};

export type AddTagsToCandidateAction = {
  type: typeof ADD_TAGS_TO_CANDIDATE,
  payload: Tag[],
}

export type GetHierarchyLevelAction = {
  type: typeof GET_HIERARCHY_LEVELS,
  payload: HIERARCHY_LEVEL[],
};

export const JOB_TABS = [
  {tabId: '/crm/jobs', label: 'Actively Hiring'},
  {tabId: '/crm/jobs/draft', label: 'Draft'},
  {tabId: '/crm/jobs/closed', label: 'Closed'},
  {tabId: '/crm/jobs/archived', label: 'Archived'},
];

export const JOB_INFO_TABS = [
  {tabId: 'overview', label: 'Overview'},
  {tabId: 'details', label: 'Details'},
  {tabId: 'matches', label: 'Matches'},
  {tabId: 'applicants', label: 'Applicants'},
  {tabId: 'settings', label: 'Settings'},
];

export const JOB_STEPS = {
  details: 'Job Details',
  description: 'Job Description',
  hiring: 'Hiring Process',
  approval: 'Approval Chain',
};

export const INDUSTRY_OPTIONS = [
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts & Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies & Equipment',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'E-learning',
  'Education Management',
  'Electrical & Electronic Manufacturing',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fundraising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'LinkedIn - industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Human Resources',
  'Import & Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology & Services',
  'Insurance',
  'International Affairs',
  'International Trade & Development',
  'Internet',
  'Investment Banking',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure, Travel & Tourism',
  'Libraries',
  'Logistics & Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing & Advertising',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Device',
  'Medical Practice',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures & Film',
  'Museums & institutions',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Non-profit Organization Management',
  'Oil & Energy',
  'Online Media',
  'Outsourcing / Offshoring',
  'Package / Freight Delivery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations & Communications',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational Facilities & Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security & Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing & Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation & Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Venture Capital & Private Equity',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine & Spirits',
  'Wireless',
  'Writing & Editing',
];

export const FUNCTIONAL_AREA_OPTIONS = [
  'Accounts, Finance, Tax, CS & Audit',
  'Agent',
  'Analytics & Business Intelligence',
  'Architecture, Interior Design',
  'Banking',
  'Insurance',
  'Beauty, Fitness, Spa Services',
  'Content & Journalism',
  'Corporate Planning & Consulting',
  'CSR & Sustainability',
  'Engineering Design & R&D',
  'Export, Import & Merchandising',
  'Fashion, Garments & Merchandising',
  'Guards & Security Services',
  'KPO, Research',
  'Hotels & Restaurants',
  'Administration',
  'HR & IR',
  'Instructional Designer',
  'IT- Hardware, Telecom, Technical Staff & Support',
  'IT Software - Application Programming, Maintenance',
  'IT Software - Client Server',
  'IT Software - DBA, Datawarehousing',
  'IT Software - E-Commerce, Internet Technologies',
  'IT Software - Embedded,EDA,VLSI,ASIC,Chip Design',
  'IT Software - ERP, CRM',
  'IT Software - Mainframe',
  'IT Software - Middleware',
  'IT Software - Mobile',
  'IT Software - Network Administration, Security',
  'IT Software - Other',
  'IT Software - QA & Testing',
  'IT Software - System Programming',
  'IT Software -  Systems, EDP, MIS',
  'IT Software - Telecom Software',
  'ITES, BPO, KPO, Customer Service, Operations',
  'Legal',
  'Marketing, Advertising, MR, PR',
  'Operations, Process, Planning, Strategy',
  'Pharma, Biotech, Healthcare, Medical, R&D',
  'Packaging',
  'Production, Maintenance, Quality',
  'Purchase, Logistics, Supply Chain',
  'Sales, BD',
  'Secretary, Front Office, Data Entry',
  'Self Employed, Consultants',
  'Shipping',
  'Site Engineering, Project Management',
  'Teaching, Education',
  'Top Management',
  'Training & Development',
  'Ticketing, Travel, Airlines',
  'TV, Films, Production',
  'Visual Merchandising',
  'Web, Graphic Design, Visualiser',
  'Other',
];

export const EMPLOYEMENT_TYPE = [
  {
    key: 'FULLTIME',
    label: 'Full-time',
  },
  {
    key: 'PARTTIME',
    label: 'Part-time',
  },
  {
    key: 'CONTRACT',
    label: 'Contract',
  },
  {
    key: 'TEMPORARY',
    label: 'Temporary',
  },
  {
    key: 'INTERN',
    label: 'Internship',
  },
];

export const CURRENCY_OPTIONS = [
  {
    key: 'USD',
    label: '$',
  },
  {
    key: 'EUR',
    label: '€',
  },
  {
    key: 'GBP',
    label: '£',
  },
  {
    key: 'SGD',
    label: 's$',
  },
  {
    key: 'MYR',
    label: 'RM',
  },
  {
    key: 'MMK',
    label: 'Ks',
  },
  {
    key: 'BDT',
    label: 'Tk',
  },
  {
    key: 'NPR',
    label: 'Rs',
  },
  {
    key: 'SLR',
    label: 'Rs',
  },
  {
    key: 'SAR',
    label: 'SAR',
  },
];

export const DETAILS_OPTIONS = [
  {
    key: 'jobDetails',
    label: 'Job Details',
  },
  {
    key: 'hiringProcess',
    label: 'Hiring Process',
  },
  {
    key: 'hiringTeam',
    label: 'Hiring Team',
  },
  {
    key: 'approvalChain',
    label: 'Approval Chain',
  },
];

export const JOB_STATUS_VALUES = [
  {key: 'DRAFT', label: 'Draft', semantic: 'primary'},
  {key: 'CLOSED', label: 'Closed', semantic: 'primary'},
  {key: 'OPEN', label: 'Open ', semantic: 'success'},
  {key: 'APPROVAL_PENDING', label: 'Approval Pending', semantic: 'warning'},
  {key: 'ONHOLD', label: 'On Hold', semantic: 'warning'},
  {key: 'REJECTED', label: 'Rejected', semantic: 'danger'},
  {key: 'CANCELLED', label: 'Cancelled', semantic: 'danger'},
];

export const JOB_INFORMATION_INITIAL_STATE = {
  id: 0,
  createdBy: 0,
  createdOn: 0,
  updatedBy: 0,
  updatedOn: 0,
  organizationId: 0,
  title: '',
  code: '',
  organizationName: '',
  departmentId: 0,
  officeId: 0,
  gradeId: 0,
  department: '',
  descriptionInternal: '',
  descriptionExternal: '',
  location: '',
  clientDomain: '',
  functionalArea: '',
  experienceStart: 0,
  experienceEnd: '',
  salaryStart: 0,
  salaryEnd: 0,
  salaryCurrency: '',
  chatbotConfigId: 0,
  mandatorySkills: ([]: Array<empty>),
  keySkills: ([]: Array<empty>),
  skillsAndExpertiseInternal: '',
  skillsAndExpertiseExternal: '',
  isPublished: false,
  isInternal: false,
  isReferralActive: false,
  isCritical: false,
  isActive: true,
  isDeleted: false,
  dueDate: 0,
  openPositions: 1,
  jobType: '',
  employeeType: '',
  jobStatus: '',
  jobDescFilekey: '',
  jobInternalDescFilekey: '',
  descriptionWordCount: 0,
  jdScore: 0,
  externalDepartment: '',
  hiringPlanId: 0,
  customFields: ([]: Array<empty>),
  user: ({}: {...}),
  hiringTeamMembers: ([]: Array<empty>),
  employeeRoleId: 0,
  internalDescSameAsExternal: true,
  gradeName: '',
  jobPositions: ([]: Array<empty>),
  departments: ([]: Array<empty>),
  sorcererOperation: '',
  active: true,
  deleted: false,
  referralActive: false,
  published: false,
  internal: false,
  ijpEligible: false,
  critical: false,
  template: false,
  jobId: 0,
  new: true,
  officeLocationIds: ([]: Array<empty>),
  duplicateOf: 0,
  workplaceType: null,
};

export const capitalizeFirstWord = (str: string): string => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const KEY_LIST_REMOVE_ON_CRUD = [
  'active',
  'chatbotConfigId',
  'code',
  'createdBy',
  'createdOn',
  'critical',
  'customFields',
  'deleted',
  'department',
  'departments',
  'descriptionWordCount',
  'externalDepartment',
  'gradeName',
  'hiringPlanId',
  'hiringTeamMembers',
  'id',
  'ijpEligible',
  'internal',
  'isActive',
  'isCritical',
  'isDeleted',
  'isInternal',
  'isPublished',
  'sReferralActive',
  'jdScore',
  'jobDescFilekey',
  'jobId',
  'jobInternalDescFilekey',
  'jobPositions',
  'jobStatus',
  'new',
  'organizationId',
  'published',
  'referralActive',
  'sorcererOperation',
  'updatedBy',
  'updatedOn',
  'user',
  'isReferralActive',
];

export const VALID_ATTACHMENTS = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.ms-excel': ['.xls', '.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'text/csv': ['.csv'],
  'video/mp4': ['.mp4'],
  'audio/mpeg': ['.mp3'],
};

export const LOADER_TEXTS = {
  jdGen: [
    'Dream job description processing...',
    'Meticulous word selection in action',
  ],
  analyze: ['One moment while we analyze your JD'],
};

export const promptsList: Array<string> = [
  'Empathetic and Supportive',

  'Friendly and Conversational',

  'Professional and Formal',

  'Gender Neutral',
];

export const ADD_PROMPT_TYPES: Array<string> = ['skills', 'responsibilities'];

export const TONE_PROMPT_TYPES: Array<string> = [
  'missingSections',
  'contentEnhancement',
];

export const PROMPT_TYPES: Array<string> = [
  ...ADD_PROMPT_TYPES,
  ...TONE_PROMPT_TYPES,
];

export const getJdScoreTextType = (score: number): string => {
  if (score > 80) {
    return 'Excellent';
  } else if (score < 80 && score > 60) {
    return 'Good, could be better';
  } else {
    return 'Inadequate, needs work';
  }
};

const validationObject: VALIDATED_OBJECT = {
  title: {
    errorText: 'Please enter title',
  },
  jobType: {
    errorText: 'Please select job type',
  },
  openPositions: {
    errorText: 'Please enter positions',
  },
  employeeType: {
    errorText: 'Please select employee',
  },
  officeId: {
    errorText: '',
  },
  employeeRoleId: {
    errorText: 'Please select role',
  },
  departmentId: {
    errorText: 'Please select department',
  },
  experienceEnd: {
    errorText: 'Please enter experience end',
  },
};

export const jobCrudValidation = (
  jobInformation: JOB_INFORMATION,
  isEmployeeRoleListAvailable: boolean,
): VALIDATED_OBJECT | null => {
  const tempObject = {...validationObject};
  Object.keys(tempObject).forEach((jobKey) => {
    if (jobKey === 'employeeRoleId') {
      if (isEmployeeRoleListAvailable) {
        if (jobInformation[jobKey]) {
          // $FlowFixMe[incompatible-type]
          delete tempObject[jobKey];
        }
      } else {
        // $FlowFixMe[incompatible-type]
        delete tempObject[jobKey];
      }
    } else {
      if (jobInformation[jobKey]) {
        // $FlowFixMe[incompatible-type]
        delete tempObject[jobKey];
      }
    }
  });

  return tempObject;
};

export const getStepTypeFormattedName = (stepType: string): string => {
  if (stepType === 'INTERNAL_FORM') {
    return 'Internal Form';
  } else if (stepType === 'BACKGROUND_VERIFICATION') {
    return 'Background check';
  }
  return '';
};

export const PACKAGE_LISTS = [
  {
    key: 'LOCAL_ADDRESS_VERIFICATION',
    label: 'Local Address Verification',
  },
  {
    key: 'EDUCATION_VERIFICATION',
    label: 'Education Verification',
  },
  {
    key: 'EMPLOYMENT_VERIFICATION',
    label: 'Employment Verification',
  },
  {
    key: 'CRIMINAL_COURT_VERIFICATION',
    label: 'Criminal Court Verification',
  },
  {
    key: 'PAN_CARD_VERIFICATION',
    label: 'Pan Card Verification',
  },
  {
    key: 'PROFESSIONAL_REFERENCE_VERIFICATION',
    label: 'Professional Reference Verification',
  },
  {
    key: 'AADHAAR_VERIFICATION',
    label: 'Aadhaar Verification',
  },
  {
    key: 'PERMANENT_ADDRESS_POSTAL_VERIFICATION',
    label: 'Permanent Address Postal Verification',
  },
];

export const BACKGROUND_VERIFICATION_PLATFORM_LIST = [
  {
    key: 'CHECKR',
    label: 'CHECKR',
  },
  {
    key: 'ONGRID',
    label: 'ONGRID',
  },
];

export const getFormattedText = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const converJobTypeString = (input: string): string => {
  const index = input.indexOf('TIME');
  let modifiedString;

  if (index === -1) {
    modifiedString = getFormattedText(input.toLowerCase());
  } else {
    modifiedString = input.slice(0, index) + '-' + input.slice(index);
    modifiedString = modifiedString.toLowerCase();

    // Capitalize the first letter of each word
    modifiedString = modifiedString
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-');
  }

  return modifiedString;
};

export const dateFormator = (date: number): string => {
  const now = moment();
  const inputDate = moment(date);
  const diffInMinutes = now.diff(inputDate, 'minutes');
  const diffInHours = now.diff(inputDate, 'hours');
  const diffInDays = now.diff(inputDate, 'days');

  if (diffInMinutes < 1) {
    return 'just now';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else if (diffInDays === 1) {
    return 'Yesterday';
  } else if (diffInDays <= 7) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  } else {
    return inputDate.format('MMM Do YYYY');
  }
};

export const getExperience = (start: number, end: number): string => {
  if (end === 0) {
    return '0 years';
  }
  return `${start} to ${end} years`;
};

export function getFormattedDate(dateInMs: number): string {
  const date = new Date(dateInMs);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const getRangeLabel = (text: string): string => {
  if (text === 'salaried') {
    return 'Salary Range';
  }
  return `${getFormattedText(text)} Range`;
};

export const getJobOptions = (jobStatus: string): Array<MenuOption> => {
  const defaultOptions = [
    {key: 'createDuplicate', label: 'Create Duplicate'},
    {key: 'cancelThisJob', label: 'Cancel This Job'},
  ];

  switch (jobStatus) {
    case 'CLOSED':
    case 'ONHOLD':
      return [{key: 'markAsOpen', label: 'Mark as Open'}, ...defaultOptions];
    case 'REJECTED':
      return [
        {key: 'reRequestForApproval', label: 'Re-Request for Approval'},
        ...defaultOptions,
      ];
    case 'DRAFT':
      return [
        ...defaultOptions,
        {key: 'saveAsTemplate', label: 'Save as Template'},
      ];
    case 'CANCELLED':
      return [
        {key: 'createDuplicate', label: 'Create Duplicate'},
        {key: 'reOpenThisJob', label: 'Re-Open This Job'},
      ];
    case 'OPEN':
      return [
        {key: 'markAsClosed', label: 'Mark as Closed'},
        {key: 'putJobOnHold', label: 'Put Job on Hold'},
        ...defaultOptions,
      ];
    case 'APPROVAL_PENDING':
    default:
      return defaultOptions;
  }
};

export const getFormatQuestionType = (input: string): string => {
  if (!input.includes('_')) {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }

  return input
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const JOB_STEPS_OPTIONS: MenuOption[] = [
  {
    label: 'Interview',
    key: 'INTERVIEW',
  },
  {
    label: 'Feedback',
    key: 'FEEDBACK',
  },
  {
    label: 'Candidate Form',
    key: 'CANDIDATE_FORM',
  },
  {
    label: 'Assessment',
    key: 'ASSESSMENT',
  },
  {
    label: 'Internal Form',
    key: 'INTERNAL_FORM',
  },
  {
    label: 'Background Verification',
    key: 'BACKGROUND_VERIFICATION',
  },
];

// function isHtmlString(text) {
//   const htmlPattern = /<[^>]+>/;
//   return htmlPattern.test(text);
// }

// export const convertToHtmlBody = (text: string, style: {...}): string => {
//   if (isHtmlString(text)) {
//     return text;
//   }
//   return `<div style=\"${style}\">${text}</div>`;
// };
