// @flow strict
import type {
  RuleBlockComponentProps,
  ReferralSetting,
} from 'src/types/referral-v2';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getRuleInfo} from 'src/components/referral-v2/common.js';
import {updateOneTimeSettings} from 'src/action-creators/referral-v2/referral-settings.js';
import {selectReferralSetting} from 'src/selectors/referral-settings.js';
import classify from 'src/utils/classify';
import {radioTimeSheetLogOption} from 'src/utils/referral-v2.js';
import {
  FormLabelSmall,
  SubTitleExtraSmall,
} from '@spaced-out/ui-design-system/lib/components/Text';
import {Dropdown} from '@spaced-out/ui-design-system/lib/components/Dropdown';
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {Card} from '@spaced-out/ui-design-system/lib/components/Card';
import {
  RadioGroup,
  RadioButton,
} from '@spaced-out/ui-design-system/lib/components/RadioButton';
import Rule from 'src/components/referral-v2/payout-rule/rule.jsx';
import rulesCss from 'src/components/referral-v2/settings/settings.css';


const EndDateRuleBlock = ({
  getEntityOptionsByFieldType,
  handleUpdateRuleBlockData,
  handleSelectRule,
  handleAddRule,
  ruleBlockData,
  entityFields,
  onDeleteRule,
  onEditRule,
  disabled,
}: RuleBlockComponentProps): React.Node => {
  const [hourlyRuleType, setHourlyRuleType] = React.useState(
    ruleBlockData?.rule_type === 'field' ? 'field' : 'timesheet',
  );

  const dispatch = useDispatch();
  const referralSettings: ?ReferralSetting = useSelector(selectReferralSetting);

  const timeSheetLogType =
    referralSettings?.one_time_settings.timesheet_log_type ?? 'days';

  const handleConfigRule = (value) => {
    if (value === 'true') {
      handleUpdateRuleBlockData('end_date_rule', {
        rule_type: 'field',
        field: {
          field_name: '',
        },
      });
      return;
    } else {
      handleUpdateRuleBlockData('end_date_rule', null);
    }
  };

  const handleChangeRuleType = (value) => {
    let data = {rule_type: value};
    if (value === 'field') {
      data = {...data, field: {field_name: ''}};
    } else {
      data = {...data, rule: []};
    }
    handleUpdateRuleBlockData('end_date_rule', data);
  };

  const handleChangeTimeSheetLogType = async (timeSheetLogType) => {
    const data = {
      rule_type: 'field',
      field: {field_name: ''},
    };
    dispatch(
      //$FlowFixMe[prop-missing]
      updateOneTimeSettings({
        ...referralSettings?.one_time_settings,
        timesheet_log_type: timeSheetLogType.key,
      }),
    );
    handleUpdateRuleBlockData('end_date_rule', data);
  };

  const handleChangeHourlyRuleType = (value) => {
    setHourlyRuleType(value);
    if (value === 'timesheet') {
      handleUpdateRuleBlockData('end_date_rule', {
        rule_type: '',
        field: {field_name: ''},
      });
    } else {
      handleUpdateRuleBlockData('end_date_rule', {
        rule_type: 'field',
        field: {field_name: ''},
      });
    }
  };

  return (
    <div className={classify(rulesCss.flexColumn, rulesCss.defaultGap)}>
      <RadioGroup
        align="horizontal-fixed"
        name="radio-group-1"
        onChange={handleConfigRule}
        classNames={{
          wrapper: rulesCss.ruleInput,
          radioGroup: rulesCss.radioGroup,
        }}
        value={ruleBlockData ? 'true' : 'false'}
      >
        <RadioButton disabled={disabled} value={'true'}>
          Yes
        </RadioButton>
        <RadioButton disabled={disabled} value={'false'}>
          No
        </RadioButton>
      </RadioGroup>
      {ruleBlockData && (
        <>
          <div className={rulesCss.defaultGap}>
            <FormLabelSmall color="secondary">
              What is the period defined in? *
            </FormLabelSmall>
            <div>
              <Dropdown
                disabled={disabled}
                menu={{
                  options: radioTimeSheetLogOption,
                  selectedKeys: [timeSheetLogType],
                }}
                onChange={handleChangeTimeSheetLogType}
                required={true}
                size="small"
                dropdownInputText={
                  radioTimeSheetLogOption.find(
                    (option) => option.key === timeSheetLogType,
                  )?.label ?? 'Select'
                }
              />
            </div>
          </div>
          {timeSheetLogType === 'hours' ? (
            <>
              <FormLabelSmall color="secondary">
                Define the rule which helps us to identify the number of hour
                lapsed for the referral in our system.
              </FormLabelSmall>
              <RadioGroup
                align="horizontal-fixed"
                name="radio-group-2"
                onChange={handleChangeHourlyRuleType}
                value={hourlyRuleType}
              >
                <RadioButton disabled={disabled} value={'timesheet'}>
                  Manually upload timesheet
                </RadioButton>
                <RadioButton disabled={disabled} value={'field'}>
                  Map a field to get the value automatically
                </RadioButton>
              </RadioGroup>
              {hourlyRuleType === 'field' && (
                <div className={rulesCss.ruleInput}>
                  <Dropdown
                    required={true}
                    disabled={disabled}
                    size="small"
                    label="Field name"
                    onChange={(option) =>
                      handleSelectRule(option, 'end_date_rule', ruleBlockData)
                    }
                    dropdownInputText={
                      ruleBlockData.field?.field_name ?? 'Select'
                    }
                    menu={{
                      classNames: {
                        wrapper: rulesCss.option,
                        optionTextContainer: rulesCss.option,
                      },
                      options: getEntityOptionsByFieldType('number') ?? [],
                      selectedKeys: ruleBlockData.field?.field_name
                        ? [ruleBlockData.field.field_name]
                        : [],
                      allowSearch: true,
                    }}
                  />
                </div>
              )}
              {hourlyRuleType === 'timesheet' ? (
                <Card classNames={{wrapper: rulesCss.chip}}>
                  <SubTitleExtraSmall color="secondary">
                    Manual Input will be required to confirm payout eligibility,
                    you can upload it through the timesheet feature in payout
                    section
                  </SubTitleExtraSmall>
                </Card>
              ) : (
                ruleBlockData.field?.field_name && (
                  <Card classNames={{wrapper: rulesCss.chip}}>
                    <SubTitleExtraSmall color="secondary">
                      We will use the "{ruleBlockData.field.field_name}" field
                      value to get the number of hours completed, payout
                      eligibility will depend on individual program level input
                    </SubTitleExtraSmall>
                  </Card>
                )
              )}
            </>
          ) : (
            <>
              <Card classNames={{wrapper: rulesCss.chip}}>
                <SubTitleExtraSmall color="secondary">
                  We will consider the referral eligible if the referral hired
                  completes the number of days as per the configuration in the
                  referral programs. the default is 90 days
                </SubTitleExtraSmall>
              </Card>
              <RadioGroup
                align="horizontal-fixed"
                name="radio-group-2"
                onChange={handleChangeRuleType}
                value={ruleBlockData.rule_type ?? 'field'}
              >
                <RadioButton disabled={disabled} value={'field'}>
                  Map a field to get the value automatically
                </RadioButton>
                <RadioButton disabled={disabled} value={'event'}>
                  Configure rule
                </RadioButton>
              </RadioGroup>
              {ruleBlockData.rule_type === 'event' ? (
                <>
                  <FormLabelSmall color="secondary">
                    Define the rule which helps us to identify the
                    retired/contract end date of the referral in our system: *
                  </FormLabelSmall>
                  {ruleBlockData.rule?.map((payoutRule, index) => {
                    const rule = entityFields.find(
                      (field) => field.value === payoutRule.field_name,
                    );
                    return (
                      <Rule
                        showAndText={index > 0}
                        disabled={disabled}
                        options={getEntityOptionsByFieldType()}
                        rule={rule}
                        fieldObject={payoutRule}
                        handleSelectRule={(option) =>
                          handleSelectRule(
                            option,
                            'end_date_rule',
                            ruleBlockData,
                            index,
                          )
                        }
                        onEdit={(ruleData) =>
                          onEditRule(
                            ruleData,
                            'end_date_rule',
                            ruleBlockData,
                            index,
                          )
                        }
                        onRemove={() =>
                          onDeleteRule(index, 'end_date_rule', ruleBlockData)
                        }
                      />
                    );
                  })}
                  <div className={rulesCss.ruleInputContainer}>
                    <Button
                      size="small"
                      iconLeftName="plus"
                      isFluid={false}
                      type="secondary"
                      disabled={disabled}
                      onClick={() =>
                        handleAddRule('end_date_rule', ruleBlockData)
                      }
                    >
                      Add a new clause
                    </Button>
                  </div>
                  {ruleBlockData.rule &&
                    ruleBlockData.rule.filter((ruleObj) => ruleObj.field_name)
                      .length > 0 && (
                      <Card classNames={{wrapper: rulesCss.chip}}>
                        <SubTitleExtraSmall color="secondary">
                          We will use the date when the condition defined above
                          is satisfied to identify the end date for the referral
                          candidate. In this case, when the field
                          {getRuleInfo(ruleBlockData.rule)}
                        </SubTitleExtraSmall>
                      </Card>
                    )}
                </>
              ) : (
                <>
                  <div className={rulesCss.ruleInput}>
                    <Dropdown
                      required={true}
                      disabled={disabled}
                      size="small"
                      label="Field name"
                      onChange={(option) =>
                        handleSelectRule(option, 'end_date_rule', ruleBlockData)
                      }
                      dropdownInputText={
                        ruleBlockData.field?.field_name ?? 'Select'
                      }
                      menu={{
                        classNames: {
                          wrapper: rulesCss.option,
                          optionTextContainer: rulesCss.option,
                        },
                        options: getEntityOptionsByFieldType('date') ?? [],
                        selectedKeys: ruleBlockData.field?.field_name
                          ? [ruleBlockData.field.field_name]
                          : [],
                        allowSearch: true,
                      }}
                    />
                  </div>
                  {ruleBlockData.field?.field_name && (
                    <Card classNames={{wrapper: rulesCss.chip}}>
                      <SubTitleExtraSmall color="secondary">
                        We will use the date shown in "
                        {ruleBlockData.field.field_name}" field to identify the
                        end date of referral candidate.
                      </SubTitleExtraSmall>
                    </Card>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EndDateRuleBlock;
