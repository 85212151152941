// @flow

import type {SenseAction} from 'src/action-creators/types';
import type {BatchAction} from 'src/action-creators/batch';

import * as Redux from 'redux';

import enableBatching, {type BatchReducer} from './batch';

import appConfig from './app-config';
import accounts from './accounts';
import accountInvites from './account-invites';
import phoneNumbers from './phone-numbers';
import displayConfig from './display-config';
import agency from './agency';
import agencyOverview from './agency-overview';
import atsEntities from './ats-entities';
import jobVariables from './job-variables';
import jobMatchMeta from './job-match-meta';
import journeyCategories from './journey-categories';
import lists from './lists';
import audienceLists from './audience-lists';
import jobWidget from './job-widget';
import analyticsGenesis from './analytics-genesis';
import jobPreview from './job-preview';
import backgroundTask from './background-task';
import audienceMembers from './audience-members';
import workflows from './workflows';
import dynamicLabels from './dynamic-labels';
import events from './events';
import eventCreation from './event-creation';
import globalVariables from './global-variables';
import namespacedGlobalVariables from './namespaced-global-variables';
import requestCache from './request-cache';
import requestHeaders from './request-headers';
import roles from './roles';
import route from './route';
import groups from './groups';
import env from './env';
import groupAccounts from './group-accounts';
import groupAudience from './group-audience';
import report from './report';
import contentSubscription from './content-subscription';
import eventTypeCounts from './event-type-counts';
import sentEvents from './sent-events';
import dueEvents from './due-events';
import eventResponses from './event-responses';
import linkStats from './link-stats';
import plots from './plot';
import npsPlots from './nps-plot';
// $FlowFixMe[untyped-type-import]
import csvImport, {type State as CsvImportState} from './csv-import';
import messages from './messages';
import threadLists from './thread-lists';
import modal from './modal';
import draftMessages from './draft-messages';
import broadcasts from './broadcasts';
import hvBroadcasts from './hv-broadcasts';
import contactsGenesis from './contacts-genesis';
import contacts from './contacts';
import contactLists from './contact-lists';
import messagesAnalytics from './messages-analytics';
import agentSettings from './agent-settings';
import flagging from './flagging';
import insightCounts from './insight-counts';
import atsSettings from './ats-settings';
import roi from './roi';
import engagementCohorts from './engagement-cohorts';
import templates from './templates';
import multientityAudience from './multientity-audience';
import emailDNS from './email-dns';
import {default as chat} from './chat';
import dataExportReport from './data-export-report';
import productFlags from './product-flags';
import audit from './audit';
import writeback from './writeback';
import csrf from './csrf';
import navigation from './navigation';
import templateHelper from './template-helper';
import errors from './errors';
import chatbot from './chatbot';
import toasts from './toasts';
import workflowActivation from './workflow-activation';
import playbooks from './playbooks';
import audienceList from './audience-list';
import audienceListOld from './audience-list.old';
import journeyAudienceOld from './journey-audience.old';
import journeyAudience from './journey-audience';
import automationWorkflowAudience from './automation-workflow-audience';
import trigger from './trigger';
import entityLifecycleGoal from './entity-lifecycle-goal';
import referralV2 from './referral-v2';
import landingPage from './landing-page';
import referralAcl from './referral-acl';
import referralNotificationUsers from './referral-notification-user';
import referralExternal from './referral-external';
import fieldMapping from './field-mapping';
import abtAttributes from './action-based-targeting-meta';
import noteWritebackMetadata from './note-writeback-metadata';
import senseAIPanel from './sense-ai-panel';
import authentication from './authentication';
import referralProgram from './programs';
import referralSettings from 'src/reducers/referral-settings';
import journeyTouchpoint from './journey-touchpoint';
import referralProgramUsers from 'src/reducers/referral-program-user';
import senseAi from './sense-ai';
import drive from 'src/reducers/drive';
import referralFilters from 'src/reducers/referral-filter';
import scheduling from './automation-workflow-scheduling';
import delay from './delay';
import automationWorkflow from './automation-workflow';
import automationWorkflowOrganizer from './automation-workflow-organizer';
import targetEntityTypes from './target-entity-types';
import email from './email';
import sms from './sms';
import fieldWriteback from './field-writeback';
import smsChatbot from './sms-chatbot';
import entityAttributes from './entity-attributes';
import pagesLeads from './leads';
import referrerPortal from './referrer-portal';

import pagesSites from './page-site';
import filter from './filter';
import surveySummary from './survey-summary';
import analyticsJourneysOverview from './analytics/analytics-journeys-overview';
import salesPerformanceAnalytics from './sales-performance-analytics';
import crm from './crm';
import analyticsWorkflowsOverview from './analytics/analytics-workflows-overview';
import split from './split';


export type SenseReducer<S> = Redux.Reducer<S, SenseAction>;

export type State = $Call<typeof combinedReducer, void, SenseAction>;
export type GetState = () => State;

// TODO (kyle): fix this once SenseAction is fixed
type DispatchAction = (SenseAction) => any;
type DispatchThunk = <R>(ThunkAction<R>) => Promise<R>;
type DispatchThunkSync = (ThunkSyncAction) => void;
type DispatchBatch = (BatchAction) => void;

export type Dispatch = DispatchAction &
  DispatchThunk &
  DispatchThunkSync &
  DispatchBatch;
export type ThunkAction<R = void> = (Dispatch, GetState) => Promise<R>;
export type ThunkSyncAction = (Dispatch, GetState) => void;

export type ReduxStore = Redux.Store<State, SenseAction, Dispatch>;

const combinedReducer: (State, SenseAction) => State = Redux.combineReducers({
  //app
  appConfig,
  // API
  accounts,
  accountInvites,
  phoneNumbers,
  displayConfig,
  agency,
  agencyOverview,
  atsEntities,
  jobVariables,
  jobMatchMeta,
  journeyCategories,
  audienceLists,
  jobWidget,
  analyticsGenesis,
  analyticsJourneysOverview,
  jobPreview,
  backgroundTask,
  audienceMembers,
  workflows,
  dynamicLabels,
  events,
  eventCreation,
  globalVariables,
  namespacedGlobalVariables,
  roles,
  groups,
  env,
  groupAccounts,
  groupAudience,
  report,
  contentSubscription,
  eventTypeCounts,
  sentEvents,
  dueEvents,
  linkStats,
  plots,
  npsPlots,
  eventResponses,
  csvImport,
  insightCounts,
  multientityAudience,
  chatbot,
  workflowActivation,
  referralV2,
  referralAcl,
  referralExternal,
  landingPage,
  fieldMapping,
  senseAIPanel,
  authentication,
  pagesLeads,
  pagesSites,

  // Framework
  requestHeaders, // important request headers from the server
  requestCache, // api cache with TTL
  route, // client routing and location
  modal, // modal service
  // Chat App
  messages,
  draftMessages,
  broadcasts,
  hvBroadcasts,
  contactsGenesis,
  threadLists,
  contacts,
  contactLists,
  messagesAnalytics,
  agentSettings,
  templates,
  chat,

  flagging,
  atsSettings,
  roi,
  engagementCohorts,
  emailDNS,

  // Data exports
  dataExportReport,

  productFlags,

  writeback,
  audit,
  csrf,
  navigation,
  templateHelper,
  errors,

  toasts,

  //playbooks
  playbooks,

  //engage
  lists,
  audienceListOld,
  audienceList,
  journeyAudienceOld,
  journeyAudience,

  //automation workflow
  scheduling,
  delay,
  automationWorkflow,
  automationWorkflowOrganizer,
  targetEntityTypes,
  email,
  sms,
  fieldWriteback,
  smsChatbot,
  entityAttributes,
  automationWorkflowAudience,
  trigger,
  filter,
  split,
  // entity lifecycle goals
  entityLifecycleGoal,
  abtAttributes,
  // note writeback metadata
  noteWritebackMetadata,
  referralSettings,
  referralProgram,
  journeyTouchpoint,
  referralProgramUsers,
  // Sense AI
  senseAi,
  drive,
  referralNotificationUsers,
  referralFilters,
  referrerPortal,
  surveySummary,

  // Analytics
  salesPerformanceAnalytics,
  analyticsWorkflowsOverview,

  // CRM
  crm,
});

const batchedReducer: BatchReducer<State, SenseAction> =
  enableBatching(combinedReducer);
export default batchedReducer;
