// @flow

import IndexStore from 'src/stores';
import type {ReduxStore} from 'src/reducers';

import * as React from 'react';
import {useSelector} from 'react-redux';
import {useRoutes, useHistory} from 'src/rerouter';
import {
  useTRMEnabled,
  useReleaseFlag,
  useAppShellEnabled,
} from 'src/hooks/product-flags';
import {workflowJourneyRedirect} from './workflow-journey-redirect';
import {container, redirect, split, onEnter, imbue} from './utils.jsx';
import usePrivilege from 'src/hooks/usePrivilege';
import useAgencyConfig, {useSenseEvents} from 'src/hooks/useAgencyConfig';
import {useAgencyMaintenanceMode} from 'src/hooks/useAgencyData';

import useDisplayConfig, {useJ2Enabled} from 'src/hooks/useDisplayConfig';

import {signOut} from 'src/actions/index';
import {isMobileDevice} from 'src/utils/responsive';
import {
  selectCurrentAccount,
  getAccountPrivileges,
} from 'src/selectors/accounts';
import {canUseMsgPhoneOrLatInbox} from 'src/selectors/chat';
import {AnalyticsService} from 'src/analytics';
import {Zipy} from 'src/zipy';

import {RouterErrorBoundary} from 'src/components/500/boundary.jsx';

import AuthContainer from 'src/components/auth/container.jsx';
import Signin from 'src/components/auth/signin.jsx';
import Signup from 'src/components/auth/signup.jsx';
import Forgot from 'src/components/auth/forgot.jsx';
import Reset from 'src/components/auth/reset.jsx';

import TopNav from 'src/components/navigation/top.jsx';
import SideNavigationContainer from 'src/components/navigation/side-nav-container.jsx';
import ExtensionConnection from 'src/components/micro-apps-extension/extension-connection.jsx';

import AppShell from 'src/components/app-shell/app-shell-container.jsx';

import DashboardContainer from 'src/components/dashboard/dashboard-container.jsx';
import EmptyDashboard from 'src/components/dashboard/empty-dashboard.jsx';
import TemplateDetail from 'src/components/settings/template/container.jsx';

import StyleGuide from 'src/components/style-guide/index.jsx';
import NotFound from 'src/components/404/404.jsx';
import NotAllowed from 'src/components/500/not-allowed.jsx';
import Error500 from 'src/components/500/500.jsx';
import DeviceNotSupported from 'src/components/500/device-not-supported.jsx';

import TestPage from 'src/components/sandbox.jsx';
import DesignDoc from 'src/components/design-doc.jsx';
import OnboardPage from 'src/components/agency-onboard/onboard-page/onboard-page.jsx';
import {MarkTaskCompletion} from 'src/components/agency-onboard/mark-task-completion/mark-task-completion.jsx';
import {MarkOnboardingCompletion} from 'src/components/agency-onboard/mark-onboarding-completion/mark-onboarding-completion.jsx';
import GenesisComponents from 'src/components/genesis-components/index.jsx';
import SenseJDAi from 'src/components/sense-jd-ai/sense-ai.jsx';

import AuthenticationContainer from 'src/components/authentication/container/container.jsx';
import SignInContainer from 'src/components/authentication/signin/signin-container.jsx';
import ForgotPasswordContainer from 'src/components/authentication/forgot/forgot-container.jsx';
import {ApprovePhoneReset} from 'src/components/authentication/signin/phone-deregistration/phone-deregistration.jsx';
import ResetPasswordContainer from 'src/components/authentication/reset/reset-password.jsx';
import SignUpContainer from 'src/components/authentication/signup/signup-container.jsx';
import {useDelightedRouteSurvey} from '../hooks/useDelighted';


const AtsRoutes = split(() => import('./ats.jsx'));
const SurveyRoutes = split(() => import('./survey.jsx'));
const ExecEmailRoutes = split(() => import('./exec-email.jsx'));
const ReferralExternalRoutes = split(() => import('./referral-external.jsx'));

const SettingsRoutes = split(() => import('./settings.jsx'));
const NewMessagesRoutes = split(() => import('./messages-new.jsx'));
const SenseAIPanelRoutes = split(() => import('./sense-ai-panel.jsx'));
const NewContactsRoutes = split(() => import('./contacts-new.jsx'));
const GenesisContactsRoutes = split(() => import('./contacts-genesis.jsx'));
const PeopleRoutes = split(() => import('./people.jsx'));
const JobsRoutes = split(() => import('./jobs.jsx'));
const AnalyticsGenesisRoutes = split(() => import('./analytics-genesis.jsx'));
const AuditRoutes = split(() => import('./audit.jsx'));
const AnalyticsRoutes = split(() => import('./analytics.jsx'));
const ScheduleRoutes = split(() => import('src/components/schedule/index.jsx'));
const ReferralRoutes = split(() =>
  import('src/components/referral/referral.jsx'),
);
const WorkflowRoutes = split(() => import('./workflow.jsx'));
const AutomationWorkflowRoutes = split(() =>
  import('./automation-workflow.jsx'),
);
const TrmRoutes = split(() => import('./trm.jsx'));
const ChatbotRoutes = split(() => import('./chatbot.jsx'));
const DbCleanupRoutes = split(() => import('./database-cleanup.jsx'));
const SchedulerRoutes = split(() => import('./scheduler.jsx'));
const LandingPageRoutes = split(() => import('./landing-page.jsx'));
const EmbeddAnalyticsRoutes = split(() => import('./embedd-analytics.jsx'));

const ContentSubscriptionRoutes = split(() =>
  import('./content-subscription.jsx'),
);
const ReferralV2Routes = split(() => import('./referral-v2.jsx'));
const CandidateDriveRoutes = split(() => import('./drive-routes.jsx'));
const MaintenanceRoutes = split(() => import('./maintenance.jsx'));

const CrmRoutes = split(() => import('./crm.jsx'));

const routeToProjectMap = {
  '/messages': 'Messaging',
  '/analytics/messages/outbound': 'Messaging',
  '/contacts/from/:id': 'Messaging',
};

export default function AppRoutes({
  senseStore,
  reduxStore,
}: {
  // $FlowFixMe[value-as-type]
  senseStore: IndexStore,
  reduxStore: ReduxStore,
}): React.Node {
  const canUseMessaging = usePrivilege('USE_MESSAGING');
  const canViewAnalytics = usePrivilege('VIEW_ANALYTICS');
  const canViewAudience = usePrivilege('VIEW_AUDIENCE_MEMBERS');
  const canViewWorkflows = usePrivilege('VIEW_WORKFLOWS');
  const canViewReferralV2 = usePrivilege('VIEW_REFERRALS_V2');
  const canEditReferralV2 = usePrivilege('EDIT_REFERRALS_V2');
  const referralAdminV2 = usePrivilege('ADMIN_REFERRALS_V2');
  const canViewTRM = useTRMEnabled();
  const canEditChatbot = usePrivilege('EDIT_CHATBOT');
  const adminReferrals = usePrivilege('ADMIN_REFERRALS');
  const recruiterReferrals = usePrivilege('RECRUITER_REFERRALS');
  const canUseReferral = adminReferrals || recruiterReferrals;
  const showSenseEvents = useSenseEvents();

  const privileges = useSelector(getAccountPrivileges) || [];
  const j2Enabled = useJ2Enabled();
  const canUsePhoneOrLatInbox = canUseMessaging || canEditChatbot;
  const canUsePhoneAndLatInbox = canUseMessaging && canEditChatbot;

  // audience + MSG => For MSG phone inbox only, but if the user has LAT inbox should have audience + MSG + chatbotEdit
  const isMessagingOnly =
    canViewAudience &&
    ((privileges.length === 2 && canUsePhoneOrLatInbox) ||
      (privileges.length === 3 && canUsePhoneAndLatInbox));

  const showOnboardPage = useAgencyConfig('onboardRequired') || false;

  const landingPageView = useDisplayConfig('landing_page_view');
  const canViewAutomationWorkflows = useDisplayConfig('aep_workflow_view');
  const useContactsGenesis = useReleaseFlag('messagingContactsRevamp');
  const isAgencyInMaintenanceMode = useAgencyMaintenanceMode();
  const useGenesisUIForAuthentication = useReleaseFlag(
    'genesisUiForAuthentication',
  );
  const appShellEnabled = useAppShellEnabled();

  const userAgent = reduxStore.getState().requestHeaders.headers['user-agent'];
  const isMobile = isMobileDevice(userAgent);

  const getIndexRedirectPath = () => {
    if (!privileges.length) {
      return '/403';
    }
    if (showOnboardPage) {
      return '/onboard';
    }
    if (isMessagingOnly || (isMobile && canUsePhoneOrLatInbox)) {
      return '/messages';
    }
    if (canViewTRM) {
      return '/trm';
    }
    if (isMobile) {
      return '/device-not-supported';
    }
    return '/dashboard';
  };

  const indexRedirectPath = getIndexRedirectPath();

  const dynamicAgency = ['auth', 'www'].includes(
    reduxStore.getState().env.hostname.split('.')[0],
  );

  const account = selectCurrentAccount(reduxStore.getState());

  React.useEffect(() => {
    handleAnalyticsEvents();
  }, []);

  const handleAnalyticsEvents = () => {
    try {
      const state = reduxStore.getState();
      if (!state) {
        return;
      }
      const {accounts, agency, productFlags} = state;
      const userId = accounts?.authedUserId;
      const data = {
        roles: accounts.data[userId].securityRole.privileges,
        release_flags: productFlags.releaseFlags,
        agencyName: agency.agency.name,
        agency_id: agency.agency.id,
        ats_kind: agency.agency.atsSystem,
      };
      Zipy.identify(userId, agency.agency.name);
      AnalyticsService.identify(userId);
      AnalyticsService.register({...data});
    } catch (error) {}
  };

  const sideNavHiddenRoutes = [
    {
      path: 'settings/templates/:templateId',
      element: imbue(TemplateDetail),
    },
    {
      path: 'messages',
      element:
        canUseMessaging || canEditChatbot ? (
          <NewMessagesRoutes store={reduxStore} />
        ) : (
          redirect('/dashboard')
        ),
    },
    {
      path: 'sense-ai-panel',
      element: <SenseAIPanelRoutes store={reduxStore} />,
    },
    {
      path: 'embedded-landing',
      element: <EmbeddAnalyticsRoutes store={reduxStore} />,
    },
    {
      path: 'reports',
      element: <AnalyticsGenesisRoutes />,
    },
    canUseMessaging && {
      path: 'contact(s)',
      element: useContactsGenesis ? (
        <GenesisContactsRoutes store={reduxStore} />
      ) : (
        <NewContactsRoutes store={reduxStore} />
      ),
    },
  ];

  const sideNavVisibleRoutes = [
    {path: '', exact: true, element: redirect(indexRedirectPath)},
    {
      path: 'dashboard',
      element: isMobile ? (
        redirect(indexRedirectPath)
      ) : canViewAnalytics ? (
        <DashboardContainer />
      ) : (
        <EmptyDashboard agentName={account?.name || ''} />
      ),
    },
    {path: 'settings', element: <SettingsRoutes />},
    {path: 'people', element: <PeopleRoutes />},
    {path: 'jobs', element: <JobsRoutes />},
    {path: 'crm', element: <CrmRoutes />},

    canViewAnalytics && {path: 'audit', element: <AuditRoutes />},
    {
      path: 'analytics',
      element: <AnalyticsRoutes store={reduxStore} />,
    },
    {
      path: 'meetings',
      element: <SchedulerRoutes />,
    },
    {
      path: 'sense-jd-ai',
      element: <SenseJDAi />,
    },
    canViewAnalytics && {
      path: 'schedule',
      element: <ScheduleRoutes />,
    },
    canUseReferral && {
      path: 'referral',
      element: <ReferralRoutes />,
      exact: true,
    },
    canViewWorkflows && {
      path: 'journey(s)',
      element: <WorkflowRoutes senseStore={senseStore} />,
    },
    canViewWorkflows &&
      j2Enabled &&
      canViewAutomationWorkflows && {
        path: 'automation-workflow(s)',
        element: <AutomationWorkflowRoutes />,
      },
    canViewTRM && {
      path: 'trm',
      element: <TrmRoutes senseStore={senseStore} />,
    },
    canEditChatbot && {
      path: 'conversation(s)',
      element: <ChatbotRoutes />,
    },
    canViewWorkflows && {
      path: 'database-cleanup(s)',
      element: <DbCleanupRoutes />,
    },
    (canEditReferralV2 || canViewReferralV2 || referralAdminV2) && {
      path: 'referralv2',
      element: <ReferralV2Routes />,
    },
    landingPageView && {
      path: 'pages',
      element: <LandingPageRoutes />,
    },
    showSenseEvents && {
      path: 'drive',
      element: <CandidateDriveRoutes />,
    },
  ];

  const topNavOnlyRoutes = [
    {path: 'style-guide', element: <StyleGuide />},
    {path: 'device-not-supported', element: <DeviceNotSupported />},
    {path: '500', element: <Error500 />},
    {path: '404', element: <NotFound />},
    {path: '403', element: <NotAllowed />},
    {
      path: '*',
      // NOTE (kyle): if they're not logged in, and they hit a 404, ask them to log in.
      element: senseStore.me.isAuthed() ? (
        <NotFound />
      ) : (
        <RequireAuth senseStore={senseStore} />
      ),
    },
  ];

  const topNavCommonRoutes = [
    {
      element: senseStore.me.isAuthed() ? (
        ({children, pathname}) => (
          <SideNavigationContainer
            key={pathname}
            children={children}
            hidden={true}
          />
        )
      ) : (
        <RequireAuth senseStore={senseStore} />
      ),
      children: sideNavHiddenRoutes,
    },
    {
      element: senseStore.me.isAuthed() ? (
        container(SideNavigationContainer)
      ) : (
        <RequireAuth senseStore={senseStore} />
      ),
      children: sideNavVisibleRoutes,
    },
    ...topNavOnlyRoutes,
  ];

  const [content, _, location] = useRoutes(
    isAgencyInMaintenanceMode
      ? [{path: '*', element: <MaintenanceRoutes />}]
      : [
          workflowJourneyRedirect,
          {path: 'uidesign', element: <DesignDoc />},
          {path: 'genesis-components', element: <GenesisComponents />},
          {
            path: 'test',
            element: <TestPage />,
          },
          {
            element: ({children}) => (
              <AuthenticationContainer
                children={children}
                senseStore={senseStore}
              />
            ),
            children: [
              {
                path: 'signup',
                element: <SignUpContainer />,
              },
              {
                path: 'signin',
                element: <SignInContainer dynamicAgency={dynamicAgency} />,
              },
              {
                path: 'signout',
                element: onEnter(() => void signOut(senseStore)),
              },
              {
                path: 'forgot',
                element: (
                  <ForgotPasswordContainer dynamicAgency={dynamicAgency} />
                ),
              },
              {
                path: 'phone/reset/:token',
                element: <ApprovePhoneReset />,
              },
              {
                path: 'reset',
                element: <ResetPasswordContainer />,
              },
            ],
          },
          {
            path: 'ats',
            element: <AtsRoutes />,
          },
          {
            path: 'survey',
            element: <SurveyRoutes />,
          },
          {
            path: 'exec-email',
            element: <ExecEmailRoutes />,
          },
          {
            path: 'referral-external',
            element: <ReferralExternalRoutes />,
          },
          {
            path: 'content-subscription/manage',
            element: <ContentSubscriptionRoutes />,
          },
          appShellEnabled && {
            element: senseStore.me.isAuthed() ? (
              container(AppShell)
            ) : (
              <RequireAuth senseStore={senseStore} />
            ),
            children: !showOnboardPage
              ? [
                  // When self-onboard is not required, always redirect from /onboard
                  {
                    path: 'onboard',
                    element: redirect(indexRedirectPath),
                  },
                ].concat(sideNavVisibleRoutes, sideNavHiddenRoutes)
              : [
                  // Show onboard page without side nav
                  {
                    path: 'onboard/task/:taskId/approve/:token',
                    element: <MarkTaskCompletion />,
                  },
                  {
                    path: 'onboard/mark-onboarding-complete',
                    element: <MarkOnboardingCompletion />,
                  },
                  {
                    path: 'onboard',
                    element: <OnboardPage />,
                  },
                ].concat(sideNavVisibleRoutes, sideNavHiddenRoutes),
          },
          {
            element: container(TopNav),
            children: !showOnboardPage
              ? [
                  // When self-onboard is not required, always redirect from /onboard
                  {
                    path: 'onboard',
                    element: redirect(indexRedirectPath),
                  },
                ].concat(topNavCommonRoutes)
              : [
                  // Show onboard page without side nav
                  {
                    path: 'onboard/task/:taskId/approve/:token',
                    element: <MarkTaskCompletion />,
                  },
                  {
                    path: 'onboard/mark-onboarding-complete',
                    element: <MarkOnboardingCompletion />,
                  },
                  {
                    path: 'onboard',
                    element: <OnboardPage />,
                  },
                ].concat(topNavCommonRoutes),
          },
        ],
  );
  useDelightedRouteSurvey(routeToProjectMap);
  return (
    <RouterErrorBoundary>
      {senseStore.me.isAuthed() ? (
        <ExtensionConnection>{content}</ExtensionConnection>
      ) : (
        <>{content}</>
      )}
    </RouterErrorBoundary>
  );
}

function RequireAuth({senseStore}) {
  const history = useHistory();
  const {location} = history;

  React.useEffect(() => {
    senseStore.me.setState({
      postAuthPath: location.pathname,
      postAuthSearch: location.search,
    });
    history.replace({
      pathname: '/signin',
      query: {
        nextPathname: location.pathname,
        nextSearch: location.search,
      },
    });
  }, []);

  return null;
}
