// @flow strict

import type {Reducer} from 'redux';
import type {
  JOB_INFORMATION,
  JOB_LISTS,
  JOB_CUSTOM_FIELDS,
  JOB_LOCATION,
  MILESTONES,
  INTERVIEW_FLOW,
  JOB_STAGES,
  USER_BASE_INFO,
  JOB_HIRING_TEAM,
  STAGE_REMOVE_RESPONSE,
  CompanyInfoJdGen,
  ApprovalObject,
  EmployeeRole,
  JOB_SCORE_CARDS,
  SCORE_CARD_CONFIG_RESPONSE,
  FEEDBACK_CONFIG_RESPONSE,
  STEP_QUESTIONNAIRE_SECTIONS_RESPONSE,
  JOB_HIRING_STEPS,
  PENDING_APPROVALS,
  CandidateList,
  ApprovalStatus,
  Tag,
  HIERARCHY_LEVEL,
} from 'src/types/crm';
import type {CrmJobActions} from 'src/action-creators/crm';
import {
  JOB_INFORMATION_INITIAL_STATE,
  UPDATE_JOB_INFORMATION,
  GET_JOB_LOCATIONS,
  GET_JOB_GRADES,
  GET_JOB_DEPARTMENTS,
  GET_CUSTOM_JOB_FIELDS,
  GET_JOB_HIRING_STAGES,
  GET_HIRING_MILESTONES,
  GET_JOB_INTERVIEW_FLOW,
  GET_HIRING_TEAM,
  REMOVE_HIRING_STAGE,
  UPDATE_COMPANY_INFO_JD_GENERATE,
  UPDATE_APPROVALS,
  GET_EMPLOYEE_ROLE,
  GET_JOB_SCORE_CARDS,
  GET_JOB_STEPS,
  GET_SCORE_CARD_CONFIG,
  UPDATE_SCORE_CARD_CONFIG,
  GET_FEEDBACK_CONFIG,
  GET_STEP_QUESTIONNAIRE_SECTIONS,
  GET_APPROVAL_REQUEST_TIME,
  SELECTED_QUESTIONNAIRE_SECTION,
  GET_JOB_MATCHES,
  GET_USER_INFO,
  GET_PENDING_APPROVALS,
  UPDATE_APPROVAL_STATUS,
  GET_TAGS,
  EDIT_TAG,
  DELETE_TAG,
  GET_HIERARCHY_LEVELS,
} from 'src/utils/crm';


type State = {
  pending_approvals: PENDING_APPROVALS | null,
  user_info: USER_BASE_INFO | null,
  job_information: JOB_INFORMATION,
  grades: JOB_LISTS[] | null,
  locations: JOB_LOCATION[] | null,
  departments: JOB_LISTS[] | null,
  milestones: MILESTONES[] | null,
  jobCustomFields: JOB_CUSTOM_FIELDS[] | null,
  jobInterviewFlow: INTERVIEW_FLOW | null,
  jobInterviewStages: INTERVIEW_FLOW | null,
  jobHiringStages: JOB_STAGES | null,
  jobHiringTeam: JOB_HIRING_TEAM | null,
  removeStage: STAGE_REMOVE_RESPONSE | null,
  jdGenCompanyInfo: CompanyInfoJdGen | null,
  approvals: Array<ApprovalObject> | null,
  employeeRoles: EmployeeRole[] | null,
  jobScoreCards: JOB_SCORE_CARDS | null,
  jobScorecardConfigData: SCORE_CARD_CONFIG_RESPONSE[] | null,
  feedbackConfiguration: FEEDBACK_CONFIG_RESPONSE | null,
  stepQuestionnaireSection: STEP_QUESTIONNAIRE_SECTIONS_RESPONSE[] | null,
  approvalRequestTime: string | null,
  jobSteps: JOB_HIRING_STEPS[] | null,
  selectedQuestionnaireSection: JOB_STAGES | null,
  jobMatches: CandidateList | null,
  approvalStatus: ApprovalStatus | null,
  tags: Tag[] | null,
  hierarchyLevels: HIERARCHY_LEVEL[] | null,
};

const initialState = {
  user_info: null,
  pending_approvals: null,
  job_information: JOB_INFORMATION_INITIAL_STATE,
  grades: null,
  locations: null,
  departments: null,
  milestones: null,
  jobInterviewFlow: null,
  jobCustomFields: null,
  jobHiringTeam: null,
  jobInterviewStages: null,
  jobHiringStages: null,
  removeStage: null,
  jdGenCompanyInfo: null,
  approvals: null,
  employeeRoles: null,
  jobScoreCards: null,
  jobScorecardConfigData: null,
  feedbackConfiguration: null,
  stepQuestionnaireSection: null,
  approvalRequestTime: null,
  jobSteps: null,
  selectedQuestionnaireSection: null,
  jobMatches: null,
  approvalStatus: null,
  tags: null,
  hierarchyLevels: null,
};

const reducer: Reducer<State, CrmJobActions> = (
  // $FlowFixMe[prop-missing]
  // $FlowFixMe[incompatible-type]
  // $FlowFixMe[incompatible-exact]
  state: State = initialState,
  action: CrmJobActions,
) => {
  switch (action.type) {
    case UPDATE_JOB_INFORMATION:
      return {
        ...state,
        job_information: action.payload,
      };
    case GET_JOB_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case GET_JOB_GRADES:
      return {
        ...state,
        grades: action.payload,
      };
    case GET_JOB_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case GET_HIRING_MILESTONES:
      return {
        ...state,
        milestones: action.payload,
      };
    case GET_CUSTOM_JOB_FIELDS:
      return {
        ...state,
        jobCustomFields: action.payload,
      };
    case GET_JOB_INTERVIEW_FLOW:
      return {
        ...state,
        jobInterviewFlow: action.payload,
      };
    case GET_JOB_HIRING_STAGES:
      return {
        ...state,
        //$FlowFixMe
        jobInterviewFlow: {...state.jobInterviewFlow, stages: action.payload},
      };
    case GET_HIRING_TEAM:
      return {
        ...state,
        jobHiringTeam: action.payload,
      };
    case REMOVE_HIRING_STAGE:
      return {
        ...state,
        removeStage: action.payload,
      };
    case UPDATE_COMPANY_INFO_JD_GENERATE:
      return {
        ...state,
        jdGenCompanyInfo: action.payload,
      };
    case UPDATE_APPROVALS:
      return {
        ...state,
        approvals: action.payload,
      };
    case GET_EMPLOYEE_ROLE:
      return {
        ...state,
        employeeRoles: action.payload,
      };
    case GET_JOB_STEPS:
      return {
        ...state,
        jobSteps: action.payload,
      };
    case GET_JOB_SCORE_CARDS:
      return {
        ...state,
        jobScoreCards: action.payload,
      };
    case GET_SCORE_CARD_CONFIG:
      return {
        ...state,
        jobScorecardConfigData: action.payload,
      };
    case UPDATE_SCORE_CARD_CONFIG:
      return {
        ...state,
        //$FlowFixMe
        jobScorecardConfigData: state.jobScorecardConfigData?.map((section) => {
          return section.id === action.payload.id ? action.payload : section;
        }),
      };
    case GET_FEEDBACK_CONFIG:
      return {
        ...state,
        feedbackConfiguration: action.payload,
      };
    case GET_STEP_QUESTIONNAIRE_SECTIONS:
      return {
        ...state,
        stepQuestionnaireSection: action.payload,
      };
    case GET_APPROVAL_REQUEST_TIME:
      return {
        ...state,
        approvalRequestTime: action.payload,
      };

    case SELECTED_QUESTIONNAIRE_SECTION:
      return {
        ...state,
        selectedQuestionnaireSection: action.payload,
      };
    case GET_JOB_MATCHES:
      return {
        ...state,
        jobMatches: action.payload,
      };
    case GET_USER_INFO:
      return {
        ...state,
        user_info: action.payload,
      };
    case GET_PENDING_APPROVALS:
      return {
        ...state,
        pending_approvals: action.payload,
      };
    case UPDATE_APPROVAL_STATUS:
      return {
        ...state,
        approvalStatus: action.payload,
      };
    case GET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case EDIT_TAG:
      return {
        ...state,
        tags: (state.tags || []).map((tag) => {
          if (tag.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return tag;
        }),
      };
    case DELETE_TAG:
      const updatedTags = state.tags
        ? state.tags.filter((tag) => tag.id !== action.payload)
        : [];
      return {
        ...state,
        tags: updatedTags,
      };
    case GET_HIERARCHY_LEVELS:
      return {
        ...state,
        hierarchyLevels: action.payload,
      };
  }

  return state;
};

export default reducer;
