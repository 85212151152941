// @noflow

import typeof IndexStore from 'src/stores/index';
import type {State as ReduxState} from 'src/reducers';
import type {Router} from 'src/types/router';
import type {AudienceMemberPlacement} from 'src/api-parsers/index';
import type {Event as SenseEvent} from 'src/api-parsers/events';
import type {JobVariableValue, JobMatchParams} from 'src/types/job-variables';

import type {
  EntityType,
  AtsEntityMappings,
  AtsEntity,
} from 'src/types/ats-entities.js';
import type {
  DynamicLabelExtra,
  DynamicLabels,
} from 'src/action-creators/dynamic-labels';

import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'src/flux/withRouter.jsx';

import includes from 'lodash/includes';
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';

import {fluxify} from 'src/flux/component.jsx';

import {selectIsWorknWorkflow} from 'src/hooks/useIsWorknWorkflow';
import {showGenericAlert} from 'src/action-creators/modal';
import {
  fetchPersonRelatedEntities,
  getEntityVariableValues,
} from 'src/action-creators/audience-members/multientity';
import {
  getJobVariableValues,
  getJobVariableValuesForAnyEntity,
} from 'src/action-creators/job-variables';
import {getEntityTypeMappings} from 'src/selectors/ats-entities';
import {createDynamicLabelSelector} from 'src/selectors/dynamic-labels';
import {selectJobVariableValuesList} from 'src/selectors/job-variables';
import {selectEnableDiscoverForEngage} from 'src/selectors/agency';

// TODO(marcos): wire these up
import {getEventWithBranches} from 'src/actions/event';
import {
  sendTestMEEmailSurvey,
  sendTestMESMSSurvey,
} from 'src/action-creators/event-creation';
import {classify, validateEmail} from 'src/utils/index';
import eventParsers from 'src/api-parsers/events';
import {selectRelatedEntitiesAsSummaries} from 'src/selectors/multientity-audience';
import {findUniqueEntityVariablesInEvent} from 'src/utils/sense-event';
import {
  entityColorMapping,
  nonPersonEntityDisplay,
  getSearchableEntity,
} from 'src/utils/entities';
import {
  getCurrentWorkflowEntityType,
  selectEventById,
} from 'src/selectors/workflow.js';

import {IntegratedMessagingSender} from './extras/integrated-messaging-sender.jsx';
import UserSearch from 'src/components/lib/user-token-search';
import Loading from 'src/components/lib/loading/loading.jsx';
import BasicDropdown from 'src/components/lib/basic-dropdown';
import TruncatedText from 'src/components/lib/truncated-text';
import {TitleBarModal} from 'src/components/modals/base-modal.jsx';

import WarningIcon from 'src/images/icons/warning-icon.svg?noAttrs';

import common from 'common';
import modalCSS from 'src/components/modals/base-modal.css';
import css from './test-email-sms-modal.css';


type MultiEntityAudience = {
  [key: string]: {[key: string]: string | number | boolean | null | void},
};

type Variables = {[key: string]: string | number | boolean | null | void};

type ReduxStateProps = {|
  atsEntityMappings: AtsEntity[],
  dynamicLabels: DynamicLabels,
  jobVariableValues: JobVariableValue[],
  workflowEntityInfo: AtsEntity,
  multiEntityAudience: MultiEntityAudience,
  variables: Variables,
|};
type ReduxDispatchProps = {|
  sendTestMEEmailSurvey: (
    workflowId: string,
    eventId: string,
    rest: {},
  ) => mixed,
  sendTestMESMSSurvey: (workflowId: string, eventId: string, rest: {}) => mixed,
  getMEPlacements: (
    audienceMemberId: string,
    entityType: EntityType,
  ) => AudienceMemberPlacement[],
  getEntityVariableValues: (personEntityId: string, type: string) => mixed,
  getJobVariableValues: (
    candidateEntityId: string,
    job_matches_count: number,
    job_matches_params: JobMatchParams,
  ) => mixed,
|};
type ReduxProps = {|
  ...ReduxStateProps,
  ...ReduxDispatchProps,
|};

const selectModalLabels = createDynamicLabelSelector();

const mapStateToProps = (state: ReduxState): ReduxStateProps => {
  const workflowEntityInfo = getCurrentWorkflowEntityType(state);
  return {
    dynamicLabels: selectModalLabels(state, workflowEntityInfo.name),
    jobVariableValues: selectJobVariableValuesList(state),
    atsEntityMappings: getEntityTypeMappings(state),
    multiEntityAudience: state.multientityAudience.entities,
    variables: state.multientityAudience.variables,
    workflowEntityInfo,
    isWorknWorkflow: selectIsWorknWorkflow(state),
  };
};

const mapDispatchToProps = {
  sendTestMEEmailSurvey,
  sendTestMESMSSurvey,
  getEntityVariableValues,
  getJobVariableValues,
  getJobVariableValuesForAnyEntity,
  getMEPlacements:
    (
      audienceMemberId: string,
      entityType: EntityType,
      personEntityType: EntityType,
    ) =>
    async (dispatch, getState) => {
      // TODO (kyle): verify that this will work with just the related entityType
      await dispatch(
        fetchPersonRelatedEntities(
          audienceMemberId,
          entityType,
          personEntityType,
        ),
      );

      return selectRelatedEntitiesAsSummaries(
        getState(),
        audienceMemberId,
        entityType,
      );
    },
  showNoJobMatchError: () => async (dispatch) => {
    dispatch(
      showGenericAlert({
        title: 'This test message won’t be sent',
        titleIcon: <WarningIcon style={{fill: modalCSS.colorWhite}} />,
        text: (
          <>
            <p>
              The test candidate that you have chosen has zero matching jobs.
              Additionally, your touchpoint settings specify that when a
              candidate has zero matching jobs, then no message will be sent.
              Therefore, for this test candidate the test message won’t be sent.
            </p>
            <p>
              To receive a test message, please select a test candidate that has
              at least one matching job or update your touchpoint settings. Note
              that the “Send To” recipient that you’ve specified in the test
              message settings doesn’t impact whether or not the test message is
              sent.
            </p>
          </>
        ),
      }),
    );
  },
};

const INVALID_EMAIL = 'Invalid Email Address!';
type Props = {|
  store: IndexStore,
  router: Router,
  eventId: string,
  workflowId: string,
  sendType: 'SMS' | 'EMAIL',
  removeModal: () => void,
  temporaryEvent: SenseEvent,
  isWorknWorkflow: boolean,
  ...ReduxProps,
|};

class SendTestEmailModal extends React.Component<
  Props,
  {
    loading: boolean,
    audienceMemberId: string,
    send_to: string,
    phoneNumber: string,
    placements: AudienceMemberPlacement[],
    relatedEntities: ?(AtsEntity[]),
    selectedEntityId: ?string,
    errors: string[],
  },
> {
  handleInputChange: (e: SyntheticEvent<*>) => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      audienceMemberId: '',
      send_to: '',
      phoneNumber: '',
      placements: [],
      relatedEntities: null,
      selectedEntityId: null,
      errors: [],
    };

    (this: any).handleSendTestEmail = this.handleSendTestEmail.bind(this);
    (this: any).handleSendTestSMS = this.handleSendTestSMS.bind(this);
    (this: any).handleAudienceMemberSelected =
      this.handleAudienceMemberSelected.bind(this);
    (this: any).handleKeyDown = this.handleKeyDown.bind(this);
    (this: any).setError = this.setError.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  render() {
    const {
      atsEntityMappings,
      dynamicLabels,
      workflowEntityInfo,
      multiEntityAudience,
      removeModal,
      sendType,
      variables,
      jobVariableValues,
      store,
    } = this.props;

    const {
      audienceMemberId,
      errors,
      loading,
      relatedEntities,
      selectedEntityId,
      send_to,
    } = this.state;
    const searchableEntity = getSearchableEntity(
      this.props.atsEntityMappings,
      this.props.workflowEntityInfo,
    );
    const isAnchoredEntityPerson = searchableEntity?.is_person;

    const dynamicLabelsByValue = keyBy(dynamicLabels, 'value');
    const _flattenedEntityVariables = variables[selectedEntityId];
    const flattenedEntityVariables = {};
    for (const key in _flattenedEntityVariables) {
      flattenedEntityVariables[key.toLowerCase()] =
        _flattenedEntityVariables[key];
    }

    const uniqueEntityVariablesInEvent = findUniqueEntityVariablesInEvent(
      this.props.temporaryEvent,
      this.props.template,
    );

    let eventVariables = getSortedEventVariables({
      flattenedEntityVariables,
      uniqueEntityVariablesInEvent,
      dynamicLabelsByValue,
    });

    const jobMatchEnabled = selectEnableDiscoverForEngage(
      store.reduxStore.getState(),
    );

    if (jobMatchEnabled) {
      const jobVariables = getJobVariables(
        uniqueEntityVariablesInEvent,
        jobVariableValues,
      );
      eventVariables = [
        ...eventVariables,
        ...jobVariableValueSchemaToEventVariableSchema(jobVariables),
      ];
    }

    const atsEntityMappingsByName = keyBy(atsEntityMappings, 'name');
    const relatedEntitiesDropdownOptions =
      relatedEntities &&
      relatedEntities.map((entity) => {
        let label;
        if (isAnchoredEntityPerson) {
          label = entity.company_name;
        } else {
          const entityDisplay =
            nonPersonEntityDisplay[workflowEntityInfo.sense_name];
          label = entity[entityDisplay.relatedEntityOptionLabel];
        }

        return {
          value: entity.id,
          label: `${label ? label + ' ' : ''}(ID: ${
            entity.external_source_id
          })`,
        };
      });

    return (
      <TitleBarModal
        className={css.container}
        title={
          this.props.sendType === 'EMAIL'
            ? 'Send Test Email'
            : this.props.isWorknWorkflow
            ? 'Send Test Notification'
            : 'Send Test SMS'
        }
        abortText={'Cancel'}
        handleAbort={removeModal}
        confirmText={'Send'}
        handleConfirm={
          sendType === 'EMAIL'
            ? this.handleSendTestEmail
            : this.handleSendTestSMS
        }
      >
        {errors.length > 0 && (
          <div className={css.content}>
            <ul className={css.errors}>
              {errors.map((error) => (
                <li className={css.errorRow} key={error}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}

        <section className={css.content}>
          {sendType === 'EMAIL' ? (
            <label className={css.row}>
              <div className={css.label}>
                <span className={css.labelText}>Send To&nbsp;</span>
                <span className={css.labelSubText}>(required)</span>
              </div>
              <input
                type="text"
                className={classify(css.input, {
                  [css.inputError]: errors.includes(INVALID_EMAIL),
                })}
                name="send_to"
                defaultValue={send_to}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                autoFocus
                placeholder="Jana@sensehq.com"
              />
            </label>
          ) : (
            <div className={css.row}>
              <div className={css.label}>
                <span className={css.labelText}>Phone Number&nbsp;</span>
                <span className={css.labelSubText}>(required)</span>
              </div>
              <input
                type="text"
                className={classify(css.input, {
                  [css.inputError]: errors.includes(INVALID_EMAIL),
                })}
                name="phoneNumber"
                placeholder="Enter Number (eg. +14086745180)"
                defaultValue={this.state.phoneNumber}
                onChange={this.handleInputChange}
              />
            </div>
          )}

          <label className={css.row}>
            <div className={css.label}>
              <span className={css.labelText}>{`Choose ${
                searchableEntity?.display_name || 'Entity'
              }`}</span>
            </div>
            <UserSearch
              entityType={searchableEntity.name}
              onSearchResultClick={this.handleAudienceMemberSelected}
              className={css.userSearch}
              showsValue={true}
              placeholder={
                isAnchoredEntityPerson
                  ? 'Jana Ally'
                  : nonPersonEntityDisplay[workflowEntityInfo.sense_name]
                      ?.typeAheadPlaceholder ?? ''
              }
              onClearSelection={() => this.setState({audienceMemberId: ''})}
              isPersonEntity={isAnchoredEntityPerson}
              workflowEntityType={workflowEntityInfo.sense_name}
            />
          </label>
        </section>

        {audienceMemberId && relatedEntities && !loading && (
          <section className={css.relatedEntitiesContainer}>
            <div className={css.label}>
              <span className={css.labelText}>
                {`Simulate ${workflowEntityInfo.display_name}`}
              </span>
            </div>
            <BasicDropdown
              options={relatedEntitiesDropdownOptions}
              onChange={(entity) => {
                this.setState({selectedEntityId: entity.value});
                this.handleFetchVariables(entity);
              }}
              selected={relatedEntitiesDropdownOptions.find(
                (option) => selectedEntityId === option.value,
              )}
              noOptionText={
                loading
                  ? 'Loading'
                  : `No ${workflowEntityInfo.display_name}s found for ${
                      multiEntityAudience[audienceMemberId]?.data?.name ??
                      multiEntityAudience[audienceMemberId]?.data?.['full name']
                    }`
              }
            />
          </section>
        )}
        {sendType === 'SMS' && (
          <IntegratedMessagingSender
            workflowId={this.props.workflowId}
            eventId={this.props.eventId}
            anchorEntityId={selectedEntityId}
            anchorEntityType={this.props.workflowEntityInfo.name}
            contentSubscriptionCategoryId={
              this.props.temporaryEvent?.content_subscription_category_id
            }
            smsFromUser={
              this.props.temporaryEvent?.brandingSettings?.smsFromUser
            }
          />
        )}

        <div className={css.variableContainer}>
          <div className={css.label}>
            <span className={css.labelText}>Variable Preview</span>
          </div>
          {audienceMemberId && !loading ? (
            <div>
              <div className={css.variableList}>
                {eventVariables.map((variable) => (
                  <VariableRow
                    variable={variable}
                    entityTypeAttrs={
                      atsEntityMappingsByName[variable.display_entity_type]
                    }
                    key={variable.value}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={css.noVariables}>
              {loading && <Loading className={css.loading} />}
              {!loading && (
                <span>
                  {eventVariables.length
                    ? 'Select a Recipient to Preview Variables'
                    : 'No variables found'}
                </span>
              )}
            </div>
          )}
        </div>
      </TitleBarModal>
    );
  }

  handleFetchVariables = async (entity: {value: string, label: string}) => {
    const {
      getEntityVariableValues,
      variables,
      workflowEntityInfo,
      temporaryEvent,
      store,
      getJobVariableValuesForAnyEntity,
    } = this.props;
    const jobMatchEnabled = selectEnableDiscoverForEngage(
      store.reduxStore.getState(),
    );

    if (!variables[entity.value]) {
      //only fetch if we do not have the variables
      this.setState({loading: true});
      await getEntityVariableValues(entity.value, workflowEntityInfo?.name);
      this.setState({loading: false});
    }
    if (jobMatchEnabled) {
      const jobModule = getJobModuleFromEvent(temporaryEvent);
      if (jobModule) {
        const job_matches_count = getJobMatchCountFromModule(jobModule);
        const job_matches_params = getJobMatchParamsFromModule(jobModule);
        await getJobVariableValuesForAnyEntity(
          entity.value,
          job_matches_count,
          job_matches_params,
          workflowEntityInfo.name,
        );
      }
    }
  };

  handleSendTestEmail() {
    const {send_to} = this.state;
    const {eventId, store} = this.props;
    this.clearErrors();

    // note (prakash): split and remove empty/falsey values
    const emails = send_to.split(',').filter(Boolean);
    if (emails.every((email) => validateEmail(email) === true)) {
      this.setState({
        loading: true,
      });
      const {temporaryEvent, workflowId} = this.props;
      const {selectedEntityId} = this.state;
      // TODO(marcos): handle this in redux
      const eventWithBranches = getEventWithBranches(store, temporaryEvent);
      const appendJobMatchFields = selectEnableDiscoverForEngage(
        store.reduxStore.getState(),
      );
      this.props
        .sendTestMEEmailSurvey(workflowId, eventId, {
          send_to,
          mocked_entity_id: selectedEntityId,
          temporary_event: eventParsers.normalize.event(
            eventWithBranches,
            appendJobMatchFields,
          ),
        })
        .then(this.props.removeModal)
        .catch((error) => {
          if (includes(error.response.errors, 'InvalidEntityId')) {
            this.setError('Could not find entity with that id.');
          } else if (
            includes(error.response.errors, 'NoJobMatchForCandidate')
          ) {
            this.props.showNoJobMatchError();
          } else {
            this.setError(
              'Error sending the test email: ' + error.response.errors,
            );
          }

          this.setState({
            loading: false,
          });
        });
    } else {
      this.setError('Invalid Email Address!');
    }
  }

  async handleSendTestSMS() {
    const {phoneNumber} = this.state;

    const {eventId, store} = this.props;
    await this.clearErrorsSynchronously();

    if (phoneNumber.length < 7) {
      this.setError('Please enter a valid phone number like 14086745180');
      return;
    }

    const send_to = phoneNumber;
    const {temporaryEvent, workflowId} = this.props;
    const {selectedEntityId} = this.state;
    // TODO(marcos): handle this in redux
    const eventWithBranches = getEventWithBranches(store, temporaryEvent);
    const appendJobMatchFields = selectEnableDiscoverForEngage(
      store.reduxStore.getState(),
    );
    this.props
      .sendTestMESMSSurvey(workflowId, eventId, {
        send_to,
        mocked_entity_id: selectedEntityId,
        temporary_event: eventParsers.normalize.event(
          eventWithBranches,
          appendJobMatchFields,
        ),
      })
      .then(this.props.removeModal)
      .catch((error) => {
        if (includes(error.response.errors, 'InvalidEntityId')) {
          this.setError('Could not find entity with that id.');
        } else if (includes(error.response.errors, 'NoJobMatchForCandidate')) {
          this.props.showNoJobMatchError();
        } else if (
          includes(error.response.errors, 'UnregisteredSenderNumber')
        ) {
          this.setError('Unregistered Sender number. Cannot send SMS.');
        } else {
          this.setError('Error sending: ' + error.response.errors);
        }

        this.setState({
          loading: false,
        });
      });

    this.setState({
      loading: true,
    });
  }

  async handleAudienceMemberSelected(audienceMemberId: string) {
    const {
      atsEntityMappings,
      getMEPlacements,
      getEntityVariableValues,
      getJobVariableValues,
      workflowEntityInfo,
      getJobVariableValuesForAnyEntity,
      temporaryEvent,
      temporaryEvent: {entityType},
      store,
    } = this.props;

    this.setState({audienceMemberId, loading: true});

    const searchableEntityType = getSearchableEntity(
      atsEntityMappings,
      workflowEntityInfo,
    )?.name;
    // NOTE (kyle): if the "searchable" entity is the same as the anchor entity,
    // we've found our anchor entity.
    if (workflowEntityInfo.name === searchableEntityType) {
      await getEntityVariableValues(audienceMemberId, workflowEntityInfo?.name);
      //only fetch job variable values when jobMatch feature is enabled
      const jobMatchEnabled = selectEnableDiscoverForEngage(
        store.reduxStore.getState(),
      );
      if (jobMatchEnabled) {
        const jobModule = getJobModuleFromEvent(temporaryEvent);
        if (jobModule) {
          const job_matches_count = getJobMatchCountFromModule(jobModule);
          const job_matches_params = getJobMatchParamsFromModule(jobModule);
          await getJobVariableValues(
            audienceMemberId,
            job_matches_count,
            job_matches_params,
          );
        }
      }
      this.setState({
        selectedEntityId: audienceMemberId,
      });
    } else {
      const relatedEntities = await getMEPlacements(
        audienceMemberId,
        entityType,
        searchableEntityType,
      );

      //fetch the entity variables for the first entity (default selected in dropdown)
      if (relatedEntities?.length) {
        const jobMatchEnabled = selectEnableDiscoverForEngage(
          store.reduxStore.getState(),
        );
        if (jobMatchEnabled) {
          const jobModule = getJobModuleFromEvent(temporaryEvent);
          if (jobModule) {
            const job_matches_count = getJobMatchCountFromModule(jobModule);
            const job_matches_params = getJobMatchParamsFromModule(jobModule);
            await getJobVariableValuesForAnyEntity(
              relatedEntities[0].id,
              job_matches_count,
              job_matches_params,
              workflowEntityInfo.name,
            );
          }
        }
        await getEntityVariableValues(
          relatedEntities[0].id,
          workflowEntityInfo?.name,
        );
      }

      this.setState({
        relatedEntities,
        selectedEntityId: relatedEntities.length ? relatedEntities[0].id : null,
      });
    }

    this.setState({loading: false});
  }

  handleInputChange(evt: SyntheticEvent<*>) {
    this.setState({[evt.currentTarget.name]: evt.currentTarget.value});
  }

  handleRelatedEntitySelected(entityId: string) {
    this.setState({
      selectedEntityId: entityId,
    });
  }

  setError(error: string) {
    this.setState({
      errors: [...this.state.errors, error],
    });
  }

  clearErrorsSynchronously() {
    return new Promise((resolve) => {
      this.setState({errors: []}, () => resolve());
    });
  }

  clearErrors() {
    this.setState({
      errors: [],
    });
  }

  handleKeyDown(event: SyntheticKeyboardEvent<>) {
    if (event.keyCode === 13) {
      this.handleSendTestEmail();
    }
  }
}

export default flow(
  withRouter,
  fluxify(({store}) => ({
    temporaryEvent: store.eventCreation.state.event,
    template: store.eventCreation.state.template,
  })),
  connect(mapStateToProps, mapDispatchToProps),
)(SendTestEmailModal);

const VariableRow = ({
  variable,
  entityTypeAttrs,
}: {
  variable: {
    name: string,
    sense_name: string,
    entityValue: string | boolean | number,
    isHtml?: boolean,
  },
  entityTypeAttrs: AtsEntity,
}) => (
  <div className={css.variableRow} key={variable.name}>
    <div className={css.variableColumn}>
      <span
        style={{
          color:
            common[entityColorMapping[entityTypeAttrs?.sense_name]?.text] ||
            common.colorGray6,
        }}
      >
        <TruncatedText text={variable.name} limit={25} />
      </span>
      <span className={css.variableEntityType}>
        {entityTypeAttrs.display_name || 'Unknown'}{' '}
      </span>
    </div>
    <div
      className={classify(css.variableValueColumn, {
        [css.unavailableVariable]:
          variable.entityValue === stringConstants.noVariable ||
          variable.entityValue === stringConstants.noVariableValue,
      })}
    >
      {variable.isHtml ? (
        <span dangerouslySetInnerHTML={{__html: variable.entityValue}} />
      ) : (
        <TruncatedText text={variable.entityValue} limit={25} />
      )}
    </div>
  </div>
);

/**
 * sorts variables so that event variables that do not exist for the person entity or related entity are at the top,
 * followed by event variables that exist but has no value,
 * and finally event variables with values
 */
const getSortedEventVariables = ({
  flattenedEntityVariables,
  uniqueEntityVariablesInEvent,
  dynamicLabelsByValue,
}: {
  flattenedEntityVariables: Variables,
  uniqueEntityVariablesInEvent: Set<string>,
  dynamicLabelsByValue: {[key: string]: DynamicLabels},
}): Array<DynamicLabel> => {
  const variablesWithValues = [];
  const nonexistantVariables = [];
  const variablesWithEmptyFields = [];

  for (const variable of uniqueEntityVariablesInEvent) {
    const entityValue =
      variable && flattenedEntityVariables[variable.toLowerCase().trim()];
    if (!dynamicLabelsByValue[variable]) {
      continue;
      //do not include variables not in the dynamicLabels map
    } else if (entityValue === null || entityValue === '') {
      variablesWithEmptyFields.push({
        ...dynamicLabelsByValue[variable],
        entityValue: stringConstants.noVariableValue,
      });
    } else if (entityValue === undefined) {
      nonexistantVariables.push({
        ...dynamicLabelsByValue[variable],
        entityValue: stringConstants.noVariable,
      });
    } else {
      variablesWithValues.push({
        ...dynamicLabelsByValue[variable],
        entityValue,
      });
    }
  }

  //concating three arrays to ensure the correct order based on variable value state
  return [
    ...nonexistantVariables,
    ...variablesWithEmptyFields,
    ...variablesWithValues,
  ];
};

const stringConstants = {
  noVariable: '(does not exist)',
  noVariableValue: '(field empty)',
};

//contains logic to map job variable values from api response
const getJobVariables = (
  uniqueEntityVariablesInEvent: Set<string>,
  jobVariableValues: JobVariableValue[],
) => {
  let jobVariablesWithValues = [];
  if (uniqueEntityVariablesInEvent.size === 0) {
    return jobVariablesWithValues;
  }
  uniqueEntityVariablesInEvent.forEach((variable) => {
    //optimizing here because all job match variables start with matched_job
    if (variable.includes('matched_job')) {
      const matchingJobVariables = jobVariableValues
        .filter((jobVariableValue) => jobVariableValue.name === variable)
        .map((jobVariableValue) => ({
          ...jobVariableValue,
          origName: jobVariableValue.name,
          //override name shown on the ui e.g. matched_job/title -> title — index
          name: `${jobVariableValue.name.split('/').pop()} — ${
            jobVariableValue.index + 1
          }`,
          description: `Matched Job ${jobVariableValue.index + 1}`,
          //for title_with_link variable, backend provides html for anchor e.g. <a href=...>variable</a>
          //this flag is used to render backend provided html content.
          isHtml: jobVariableValue.name === 'matched_job/title_with_link',
        }));
      jobVariablesWithValues = [
        ...jobVariablesWithValues,
        ...matchingJobVariables,
      ];
    }
  });

  return jobVariablesWithValues;
};

/** (diwakersurya) SM-136 normal entity variable schema is diff from job variable
 * schema so we need to append fields which conform to the Components using
 * entity variable because we need to show job variables in the same
 * way as entity variables.
 */
const jobVariableValueSchemaToEventVariableSchema = (
  jobVariables: JobVariable[],
) =>
  jobVariables.map((jobVariable) => ({
    ...jobVariable,
    sense_name: 'job_match_variable',
    entityValue: jobVariable.value,
    display_entity_type: 'job_match_variable',
  }));

const getJobModuleFromEvent = (event) => {
  //for message_survey_module,include_job_block decides whether it includes a job block
  //for sms_message_survey_module includes_job_match_variables decides whether it includes a job block
  const jobModule = event.questions.find(
    (question) =>
      ['sms_message_survey_module', 'message_survey_module'].includes(
        question.type,
      ) &&
      (question?.include_job_block === true ||
        question?.includes_job_match_variables === true),
  );
  return jobModule;
};

const getJobMatchCountFromModule = (module) => {
  let job_matches_count = 0;
  switch (module.type) {
    case 'sms_message_survey_module':
      job_matches_count = 1; //always 1 for sms
      break;
    case 'message_survey_module':
      job_matches_count = module.job_matches_count;
      break;
    default:
      break;
  }
  return job_matches_count;
};

const getJobMatchParamsFromModule = (module) => module.job_matches_params;

export {SendTestEmailModal, stringConstants, getSortedEventVariables};
